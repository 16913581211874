import * as React from 'react';
import 'antd/dist/antd.css';
import Layout from './layout/Layout'
import { MainRoutes } from './router/MainRoute';

function App() {
    return (
        <Layout>
            <React.Suspense fallback="loading">
                {MainRoutes}
            </React.Suspense>
        </Layout>
    );
}

export default App;
import layoutLangs from './layout/enUS';
import homeLangs from './home/enUS';
import searchLangs from './search/enUS';
import jobSeekersLangs from './job_seekers/enUS';
import companiesLangs from './companies/enUS';
import askedsLangs from './asked/enUS';
import profilesLangs from './profiles/enUS';
import termsLangs from './terms/enUS';

export default {
    ...layoutLangs,
    ...homeLangs,
    ...searchLangs,
    ...jobSeekersLangs,
    ...companiesLangs,
    ...askedsLangs,
    ...profilesLangs,
    ...termsLangs,
    "turkish": "Türkçe",
    "russian": "Русский",
    "fethiyegucbirligi": "FETHİYE GÜÇ BİRLİĞİ",
    "fethiyegucbirligifullname": "Fethiye Güç Birliği Turizm Tanıtım ltd şti.",
    "login": "Login",
    "signup": "Sign Up",
    "signout": "Sign Out",
    "website": "Website",
    "home": "Home",
    "corporate": "Corporate",
    "examservices": "Exam Services",
    "legalresponsibilitiesandincentives": "Legal Responsibilities and Incentives",
    "nationalqualifications": "National Qualifications",
    "addressfethiye": "Taşyaka Mahallesi 144. Sk No:1/A Daire 4-Daire 2 Fethiye /MUĞLA",
    "phonefethiye": "+90 252 612 84 81",
    "faxfethiye": "+90 252 612 84 82",
    "emailfethiye": "info@fethiyegucbirligi.com",
    "frequentlyaskedquestions": "Frequently Asked Questions",
    "application": "Application",
    "name": "Name",
    "surname": "Surname",
    "message": "Message",
    "submit": "Submit",
    "reset": "Reset",
    "search": "Search",
    "filter": "Filter",
    "acceptmessage": "I accept that my submitted data is collected and stored.",
    "isrequired": "is required",
    "accept": "Accept",
    "contactform": "Contact Form",
    "contact": "Contact",
    "phone": "Phone",
    "email": "E-Mail",
    "address": "Address",
    "location": "Location",
    "subscribe": "Subscribe Us",
    "send": "Send",
    "username": "User Name",
    "password": "Password",
    "remember": "Remember",
    "loginform": "Login Form",
    "userinformation": "User Information",
    "firstname": "First Name",
    "lastname": "Last Name",
    "confirmpassword": "Confirm Password",
    "intro": "Intro",
    "gender": "Gender",
    "agreement": "I have read the agreement",
    "register": "Register",
    "registrationconfirmationsuccessful": "Registration Confirmation Successful!",
    "shouldacceptagreement": "Should accept Agreement",
    "pleaseconfirmyourpassword": "Please confirm your password",
    "thetwopasswordsthatyouentereddonotmatch": "The two passwords that you entered do not match!",
    "theinputisnotvalidemail": "The input is not valid E-mail!",
    "registrationform": "Registration Form",
    "male": "Male",
    "female": "Female",
    "other": "Other",
    "dublicateusername": "This username already exists!",
    "dublicatemailaddress": "This email already exists!",
    "iforgotmypassport": "I forgot my passport",
    "areyousuretoresetpassword": "Are you sure you want to reset your password?",
    "yourpasswordresethasbeensuccessful": "Your password reset has been successful",
    "pleaseinputyouremail": "Please,input your e-mail or username!",
    "pleasecheckyouremail": "Please,check your e-mail!",
    "usernameorpasswordnotfound": "Username or password not found!",
    "usernameormailnotfound": "Username or e-mail not found!",
    "userisnotactive": "The user is not active!. Please check your e-mail address for confirmation!",
    "procesessissuccessful": "Process is successful",
    "userlanguage": "User Language",
    "undefined": "undefined",
    "totalitems": "Total items",
    "views": "Views",
    "view": "View",
    "showonmap": "Show on Map",
    "bolum": "Departman",
    "askerlik-durumu": "Military Status",
    "jobstatus": "Job Status",
    "kariyer-seviyesi": "Career Level",
    "deneyim": "Experience",
    "pozisyon": "position",
    "sector": "Sector",
    "cinsiyet": "Gender",
    "yas-araligi": "Age Range",
    "surucu-belgesi": "Driver's License",
    "jobdescription": "Job Description",
    "addfavorite": "Add Favorite",
    "applyforjob": "Apply for Job",
    "contactcompany": "Contact Company",
    "clickformore": "Click For More...",
    "releasedate": "Release Date",
    "last_one_hour": "Last one hour",
    "last_24_hours": "Last 24 hours",
    "last_7_days": "Last 7 days",
    "last_14_days": "Last 14 days",
    "last_30_days": "Last 30 days",
    "all": "All",
    "applyfilter": "Apply Filters",
    "clearfilter": "Clear Filters",
    "description": "Description",
    "header": "Header",
    "startDate": "Start Date",
    "endDate": "End Date",
    "exist": "Exist",
    "save": "Save",
    "otherinformation": "Other Information",
    "companyinformation": "Company Information",
    "socialmediainformation": "Social Media Information",
    "addressinformation": "Address Information",
    "postanewad": "Post a new Ad",
    "admanagement": "Ad Management",
    "applicationsmade": "Applications Made",
    "registeredcandidates": "Registered Candidates",
    "packages": "Packages",
    "following": "Following",
    "myfavoritejobs": "My Favorite Jobs",
    "companiesifollow": "Companies I follow",
    "jobalert": "Job Alert",
    "pleasesignin": "Please! Sign In",
    "createad": "Create Ad",
    "packageselectionandpayment": "Package Selection And Payment",
    "approve": "Approve",
    "general": "Genel",
    "title": "Title",
    "content": "Content",
    "jobtype": "Job Type",
    "pozisyon": "Position",
    "salary": "Salary",
    "salarytype": "Salary Type",
    "jobminsalary": "Job Min Salary",
    "jobmaxsalary": "Job Max Salary",
    "state": "State",
    "city": "City",
    "ackagename": "Package Name",
    "packagecontent": "Package Content",
    "packageduration": "Package Duration",
    "adduration": "Ad Duration",
    "price": "Price",
    "alljobcount": "All Job Count",
    "favoritejobcount": "Favorite Job Count",
    "years": "Years",
    "days": "Days",
    "next": "Next",
    "previous": "Previous",
    "please!selectapackage": "Please! Choose a Package",
    "applicationcount": "Application Count",
    "total": "Total",
    "used": "Used",
    "remaining": "Remaining",
    "paiddate": "Paid Date",
    "expiredate": "Expire Date",
    "active": "Active",
    "passive": "Passive,",
    "yes": "Yes",
    "no": "No",
    "add": "Add",
    "company": "Company",
    "person": "Candidate",
    "admin": "Admin",
    "changeuserrole": "Change User Role",
    "userrolename": "User Role Name",
    "percentage": "Percentage",
    "controlpanel": "Control Panel",
    "postauthor": "Post Author",
    "postdate": "Post Date",
    "infocard": "Info Card",
    "delete": "delete",
    "edit": "edit",
    "pleasesearch": "Please! Search Anyting",
    "employer": "Employer",
    "error": "Error",
    "youarenotauthorized": "You must be a Registered Company and Login in order to post an Ad, benefit from the Candidate Pool, and Manage Your Ads and Applications./n If you are a member of our Company, you can login immediately;",
    "youarenotauthorized1": "You Are Not Authorized On This Page! Only Companies Can Transaction On This Page",
    "actions": "Actions",
    "areyousuretodeletethistask": "Are you sure to delete this task?",
    "true": "True",
    "false": "False",
    "createDate": "Create Date",
    "date": "Date",
    "wc-completed": "Order Completed",
    "wc-on-hold": "Order On Hold",
    "companyname": "Company Name",
    "confirmation": "Confirmation",
    "confirmtheorder": "Confirm the Order",
    "postthejob": "Post the Job",
    "warning": "Warning",
    "tobedeterminedatthemeeting": "To be determined at the meeting",
    "rolename": "Role Name",
    "reject": "Reject",
    "upload": "Upload",
    "ads": "Ads",
    "displayname": "Display Name",
    "jobapplied": "Job Applied",
    "pleaselogin": "Please Login",
    "pleaseloginagain": "Please Login Again!",
}
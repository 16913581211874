export default {
    "ch1": "ELEMAN MI ARIYORSUNUZ?",
    "ch2": "Aradığınız Elemanı Bulmanın En kIsa Yolu: fethiyekariyer.com",
    "ch3": "Ücretsiz Üye Olun",
    "ch3-1": "Ücretsiz fethiyekariyer.com üyeliğinizi oluşturun.",
    "ch4": "İş İlanınızı Yayınlayın",
    "ch4-1": "İlanlarınızı sistemden online olarak kolayca, hemen yayınlayın.",
    "ch5": "Başvuruları Yönetin",
    "ch5-1": "Başvuruları yönetin, özgeçmişleri ayrıntılı olarak inceleyin.",
    "ch6": "Ve İşe Alın",
    "ch6-1": "Adayları davet edin, iş görüşmelerini yönetin. Doğru adayı işe alın.",
    "ch7": "ELEMAN BULMAK ARTIK DAHA KOLAY",
    "ch7-1": "fethiyekariyer.com’u kullanarak, istediğiniz yeteneklere sahip doğru elemanı bulmanızı sağlar. Tüm istihdam süreçlerinizi hızlı ve kolayca yönetebilirsiniz.",
    "ch8": "İpucu: Hızlı Eleman Bulmak İçin İlanlarınızı Öne Çıkarın",
    "ch9": "FİRMALAR İÇİN AYRICALIKLAR",
    "ch9-1": "fethiyekariyer.com İle İstihdam Süreciniz Daha Kolay",
    "ch10": "Gelişmiş Gösterge Paneli",
    "ch10-1": "Firma profilini güncelleme, ilan yönetimi, paket satın alarak ilanları öne çıkarma, başvuruları yönetme. Ve daha pek çok şey, gelişmiş gösterge paneli ile daha kolay ve hızlı.",
    "ch11": "Mülakat Listesi",
    "ch11-1": "Profilİlginizi çeken adayları listenize ekleyip, mevcut pozisyonlar için veya gelecekte görüşmeye çağırabilirsiniz.",
    "ch12": "Tüm Başvurular",
    "ch12-1": "Tüm başvuru sahiplerini ilan bazında veya topluca görebilir, değerlendirebilir, onları mülakat listesine alabilirsiniz.",
    "ch13": "İlan Yönetimi",
    "ch13-1": "Mevcut ilanı çoğaltma, ilanları güncelleme, eski ilanları silme, ilan bazında başvuruları takip etme. Hepsi daha kolay ve daha hızlı.",
    "ch14": "Yorum Etkisi",
    "ch14-1": "Adayların, firmanız hakkında yaptıkları yorumlar ile insan kaynakları yönetim ve yaklaşımlarınıza yön verebilirsiniz. ",
    "ch15": "Başvuru Uyarıları",
    "ch15-1": "Akıllı uyarı sistemi, ilanlarınıza yapılan aday başvurularından sizi haberdar eder. Siz de başvurları kaçırmazsınız.",

}

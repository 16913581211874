export default {
    "jobseekersheader1": "THE EASY WAY TO FIND A JOB",
    "jobseekersheader2": "Job Postings Waiting to Meet You Soon",
    "jsh3": "Sign Up Now",
    "jsh3-1": "To benefit from the advanced possibilities of the system, first become a member.",
    "jsh4": "Search Job Postings",
    "jsh4-1": "Find your job with advanced search features in all postings for the job you are looking for.",
    "jsh5": "Application and Monitoring",
    "jsh5-1": "After you apply for the ad, follow the reading in your account, stay in touch with the company.",
    "jsh6": "Get a Job Offer",
    "jsh6-1": "Allow your resume to be viewed, Let companies find you, make job offers.",
    "jsh7": "FINDING A JOB IS NOW EASIER",
    "jsh7-1": "You have learned how quickly you can find a job with the above advantages. You can start your Prospect Membership right now or start by browsing Job Postings",
    "jsh8": "Tip: Let Employers Find You with a Complete Profile",
    "jsh9": "PRIVILEGES TO REACH YOUR CAREER GOAL",
    "jsh9-1": "Your Career Journey Is More Enjoyable With fethiyekariyer.com",
    "jsh10": "My Favorite Ads",
    "jsh10-1": "You can add to your favorites and follow the postings that interest you and that you do not want to apply for at the moment, you can apply whenever you want.",
    "jsh11": "Advanced Dashboard",
    "jsh11-1": "The more complete your profile information and resume, the easier it will be for you to find a job. You can do all this easily and quickly with the advanced dashboard.",
    "jsh12": "Profile and Resume",
    "jsh12-1": "When you complete the advanced profile and resume sections, you can be included in the candidate pool. Employers can find you even when you are not looking at the postings.", "english": "English",
    "jsh13": "Comment Effect",
    "jsh13-1": "The comments you make on the advertisements support the employer and the platform with the points you give, you can attract the attention of the employers.",
    "jsh14": "Posts I applied for",
    "jsh14-1": "It is important for you to follow up on the postings you have applied for. You can follow which application is being evaluated and which are pending.",
    "jsh15": "Post Alerts",
    "jsh15-1": "You can keep the filter criteria you used while searching for the advertisements, you will be automatically notified when the appropriate advertisements are received.",
}
export default {
    "ch1": "LOOKING FOR EMPLOYEE?",
    "ch2": "The Shortest Way To Find The Person You Are Looking For: fethiyekariyer.com",
    "ch3": "Register for Free",
    "ch3-1": "Create your free fethiyekariyer.com membership.",
    "ch4": "Post Your Job",
    "ch4-1": "Post your ads online easily and immediately.",
    "ch5": "Manage Applications",
    "ch5-1": "Manage applications, review resumes in detail.",
    "ch6": "And Hire",
    "ch6-1": "Invite candidates, manage job interviews. Hire the right candidate.",
    "ch7": "FINDING EMPLOYEES IS NOW EASIER",
    "ch7-1": "Using fethiyekariyer.com, it allows you to find the right employee with the skills you want. You can manage all your employment processes quickly and easily.",
    "ch8": "Tip: Highlight Your Ads to Find Fast Employees",
    "ch9": "PRIVILEGES FOR COMPANIES",
    "ch9-1": "Your Employment Process is Easier with fethiyekariyer.com",
    "ch10": "Advanced Dashboard",
    "ch10-1": "Updating company profile, managing ads, promoting ads by purchasing packages, managing applications. And many more, easier and faster with the advanced dashboard.",
    "ch11": "Interview List",
    "ch11-1": "ProfileYou can add interested candidates to your list and invite them for an interview for current positions or in the future.",
    "ch12": "All Applications",
    "ch12-1": "You can see and evaluate all applicants on the basis of advertisements or collectively, and you can add them to the interview list.",
    "ch13": "Post Management",
    "ch13-1": "Duplicating the current posting, updating the postings, deleting the old postings, tracking the applications by posting. All easier and faster.",
    "ch14": "Comment Effect",
    "ch14-1": "You can direct your human resources management and approaches with the comments made by the candidates about your company.",
    "ch15": "Application Warnings",
    "ch15-1": "The smart warning system notifies you of the candidate applications made to your postings. You will not miss the applications.",
}
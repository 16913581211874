import React, { useEffect } from 'react';
import { notification } from '../components/base/BaseProps';
import { connect } from "react-redux";
import { alertActions } from '../store/actions/alert.actions';
import i18n from '../i18n';


const AlertContainer = (props) => {

    useEffect(() => {
        if (props.alert.message) {
            var message = props.alert.i18n ? i18n.t(props.alert.i18n) : props.alert.message;
            notification(props.alert.type, props.alert.header, message);
            setTimeout(props.clearAlert(), 1000)
        }
        //eslint-disable-next-line
    }, [props.alert.message]);
    return <></>;
}

const stateCreators = state => {
    return { alert: state.alert };
}
const actionCreators = { clearAlert: alertActions.clear }

export default connect(stateCreators, actionCreators)(AlertContainer);
import React, { useEffect } from 'react';
import { Form, Input, Button, Row, Col, Space, Popconfirm, message } from 'antd';
import { connect } from "react-redux";
import { compose } from "redux";
import { sha256 } from 'js-sha256';
import { authActions } from '../../store/actions/auth.actions'
import i18n from '../../i18n';
import { withTranslation } from 'react-i18next';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import RegistrationModal from '../user/RegistrationModal';
import { authService } from '../../services/auth.services';

const LoginForm = (props) => {
    const [form] = Form.useForm();

    //useEffect(() => {
    //    if (props.loggedIn) {
    //        //if (props.userRole === "Admin") {
    //        //    document.location.href = "/admin-page";
    //        //} else {
    //        //    document.location.href = "/profile-page";
    //        //}
    //    }
    //}, [props.loggedIn])

    const onFinish = (values) => {
        console.log('Success:', values);
        const { username, password } = values;
        props.login(username, sha256(password));
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onConfirmResetPassword = () => {
        const username = form.getFieldValue("username");
        if (!username) {
            message.error(i18n.t("pleaseinputyouremail"));
            return;
        } else {
            authService.resetPassword(username).then(result => {
                message.success(i18n.t("pleasecheckyouremail"));
            }).catch(err => {
                if (err.i18n) {
                    message.error(i18n.t(err.i18n));
                } else {
                    message.error(err.Errors);
                }
            });
        }

    }

    return (
        <Row>
            <Col span="24">
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                >
                    <Form.Item label={i18n.t("username")} name="username" rules={[{ required: true }]} >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={i18n.t("username")} />
                    </Form.Item>

                    <Form.Item label={i18n.t("password")} name="password" rules={[{ required: true }]}  >
                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder={i18n.t("password")} />
                    </Form.Item>
                    {/* <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                        <Checkbox>{i18n.t("remember")}</Checkbox>
                    </Form.Item> */}
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Popconfirm
                            title={i18n.t("areyousuretoresetpassword")}
                            onConfirm={onConfirmResetPassword}
                        >
                            <Button type="link">{i18n.t("iforgotmypassport")}</Button>
                        </Popconfirm>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Space>
                            {props.loggingIn ?
                                <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                :
                                <Button type="primary" htmlType="submit" >{i18n.t("login")}</Button>
                            }
                            <span onClick={() => props.handleCancel ? props.handleCancel() : null}>
                                <RegistrationModal />
                            </span>
                        </Space>
                    </Form.Item>
                </Form >
            </Col>
        </Row>

    );
}

const mapStateToProps = (state) => {
    const { loggingIn, loggedIn } = state.authentication;
    const { UserRole: userRole } = state.authentication.user;
    return { userRole, loggingIn, loggedIn };
}

const mapDispatchToProps = {
    login: authActions.login,
    logout: authActions.logout,
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(LoginForm);
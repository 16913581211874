export default {
    "header-1": "Kariyer Hedefiniz İçin Önemli Bir Adım",
    "header-2": "Size Uygun İş İlanları Burada",
    "header-3": "ÖNE ÇIKAN İLANLAR",
    "header-4.1": "Üyelerimizden Gelen Görüş ve Öneriler Doğrultusunda Yenileniyoruz",
    "header-4": "Sizinle En Kısa Sürede Buluşmayı Bekleyen İlanlar",   
    "hpg5":"İŞ BULMAK ARTIK DAHA KOLAY",
    "hpg5-1":"Aday olarak kaydolun, profilinizi eksiksiz doldurun, iş verenler sizi daha kolay bulsunlar. Siz de iş ararken kullandığınız arama koşullarını için oluşturacağınız  İLAN UYARILARI’ları ile yeni bir ilandan hemen haberdar olun.",
    "hpg6":"YETENEKLERİ BULUN, İŞİNİZE ODAKLANIN",
    "hpg6-1": "İşveren kaydınızı oluşturun, firma profilini detaylı girin, ilanları, ilanlara başvuranları detaylı görüntüleyin. Mülakata çağırdıklarını ayrı takip edin. Aradığınız yeteneği bulmak artık daha kolay, hatta ilan vermeden önce Aday Havuzu’na da bakabilirsiniz. Belki de aradığınız yetenek sizi bekliyordur.",
}

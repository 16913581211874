import { drpDataActionTypes as type } from '../actionTypes/drp.data.action.types';
import { systemService } from '../../services/system.service';
import { baseActions } from './base.actions';
import { alertActions } from './alert.actions';


let started = false;

const load = () => {
    if (started) {
        return (dispatch) => { };
    }
    started = true;
    return (dispatch) => {
        const promises = [];
        promises.push(systemService.getDropDownAllDatas());
        promises.push(systemService.getTermDatas("sector"));
        promises.push(systemService.getTermDatas("jobtype"));
        promises.push(systemService.getStates(3662));
        promises.push(systemService.getCities(3662));
        dispatch(baseActions.setLoading(true));
        Promise.all(promises).then(values => {
            started = false;
            var data = {
                ...values[0],//all drp data objects
                sector: values[1],
                jobType: values[2],
                states: values[3],
                cities: values[4],

            };
            dispatch(baseActions.setLoading(false));
            dispatch(load(data))
        }).catch(function (result) {
            started = false;
            dispatch(baseActions.setLoading(false));
            dispatch(alertActions.error(null, result.Errors, result.i18n));
        });
    }

    function load(data) {
        return { type: type.load, payload: data }
    }
}

const clear = () => ({
    type: type.clear
});

export const drpDataActions = { load, clear };

import { callApi } from "./base.services";
import { decodeToken } from "react-jwt";
import { isMobile } from "react-device-detect";

async function login(username, password) {
    var serviceName = "User/Authenticate";
    const model = { username, password, isMobile };
    try {
        let response = await callApi(serviceName, { method: "POST", data: JSON.stringify(model) });
        const data = decodeToken(response.token);
        if (isMobile) {
            //not routing
        } else if (data.UserRole === "Admin") {
            document.location.href = "/admin-page";
        } else {
            document.location.href = "/profile-page";
        }
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}

async function registration(model) {
    var serviceName = "User/Registration";
    try {
        let response = await callApi(serviceName, { method: "POST", data: JSON.stringify(model) });
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}

async function resetPassword(username) {
    var serviceName = "User/ResetPassword?email=" + username;
    try {
        let response = await callApi(serviceName, {});
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}

async function logout() {
    sessionStorage.clear();
    localStorage.removeItem("AuthStorage");
    //localStorage.removeItem("fkDrpData");
}

export const authService = { login, logout, registration, resetPassword };
import React from 'react';
import { Row, Col, Button, Space } from 'antd';
import { withTranslation } from 'react-i18next';
import { PhoneOutlined } from '@ant-design/icons';
import i18n from '../i18n';
import { isMobile } from "react-device-detect";

function PrivacySection() {
    if (isMobile) {
        return (
            <div class="privacy-section privacy-section-mobile">
                <Row wrap={false}>
                    <Col className="gutter-row" span={24}>
                        <div>
                            <Button type="link">Copyright © Fethiye Kariyer , All Rights Reserved.</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    } else {
        return (
            <div class="privacy-section privacy-section-desktop">
                <Row gutter={16} wrap={false}>
                    <Col className="gutter-row" span={6}>
                        <div>
                            <Button type="link">Copyright © Fethiye Kariyer , All Rights Reserved.</Button>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={3}>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        {/*<div>*/}
                        {/*    <Button type="link"><Space><PhoneOutlined />{i18n.t("phonefethiye")}</Space></Button>*/}
                        {/*</div>*/}
                    </Col>
                    <Col className="gutter-row" span={3}>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <div>
                            <Button type="link">Privacy Policy Terms & Conditions</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

}
export default withTranslation()(PrivacySection);

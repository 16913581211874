export default {
    "footer-1": "Fethiye Güç Birliği Eğitim Danışmanlık Turizm Tanıtım Limited Şirket Özel İstihdam Bürosu olarak 10/12/2020 tarihinden itibaren faaliyette bulunmak üzere Türkiye İş Kurumu tarafından 03/12/2020 tarih ve 7333354 sayılı karar uyarınca 1205 nolu belge ile faaliyet göstermektedir. 4904 sayılı Kanun uyarınca iş arayanlardan ücret alınması yasaktır. Şikâyetleriniz için aşağıdaki telefon numaralarına başvurabilirsiniz. Türkiye İş Kurumu Muğla İl Müdürlüğü: 0(252) 214 8420 - 0(252) 214 8803 . Türkiye İş Kurumu Muğla-Fethiye Hizmet Merkezi: 0(252) 612 82 18",
    "footer-1.2": "Fethiye Güç Birliği Eğitim Danışmanlık Turizm Tanıtım Limited Şirketi. Türkiye İş Kurumu Muğla İl Müdürlüğü: 0(252) 214 8420 - 0(252) 214 8803 . Türkiye İş Kurumu Muğla-Fethiye Hizmet Merkezi: 0(252) 612 82 18",
    "jobpostings": "İş İlanları",
    "jobseekers":"İş Arayanlar",
    "companies":"Firmalar",
    "CVPool":"CV Havuzu",
    "adpackages":"Reklam Paketleri",

    "technicalsupportline-1": "Teknik Destek Hattı",
    "technicalsupportline-2":"Pzts - Cuma 09:00 - 17:30 arası",
    "candidateprocesses": "Aday İşlemleri",
    "companytransactions": "Firma İşlemleri",
    "legalterms": "Yasal Koşullar",
    "loginregistration": "Giriş / Kayıt İşlemleri",
    "postanad": "İlan Yayınla",
    "postcvpackages": "İlan / CV Paketleri",
    "sitetermsofuse": "Site Kullanım Koşulları",
    "warrantyrefundcancellation": "Garanti - İade - İptaller",
    "kvkklightingtext": "KVKK Aydınlatma Metni",

    "dashboard": "Gösterge Paneli",
    "profile": "Profil",
    "resume": "Özgeçmiş",
    "postingsiappliedfor": "Başvurduğum İlanlar",
    "myfavoriteworks": "Favori İşlerim",
    "companiesifollow": "Takip Ettiğim Firmalar",
    "changemypassword": "Şifremi Değiştir",
    "jobalerts": "İş Uyarıları",
}

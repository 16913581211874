import React, { useState } from "react";
import { Button, Modal, Spin } from "antd";
import { connect } from "react-redux";
import { compose } from "redux";
import LoginForm from "./LoginForm";
import i18n from '../../i18n';
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const LoginModal = (props) => {
    const [state, setState] = useState({ modalVisible: false });

    const showModal = () => {
        setState({ ...state, modalVisible: true });
    };

    const handleCancel = () => {
        setState({ ...state, modalVisible: false });
    };

    if (props.loggedIn) {
        return (
            <Button type="text" >
                <Link to="/profile-page">
                    {i18n.t("userinformation")}
                </Link>
            </Button>
        );
    } else {
        return (
            <>
                <Button type="text" onClick={showModal}>
                    {i18n.t("login")}/{i18n.t("signup")}
                </Button>
                <Modal width={700} title={i18n.t("loginform")} visible={state.modalVisible} cancelButtonProps={{ hidden: true }} okButtonProps={{ hidden: true }} onCancel={handleCancel}>
                    <Spin spinning={props.loading}>
                        <LoginForm handleCancel={handleCancel} />
                    </Spin>
                </Modal >
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { loggedIn: state.authentication.loggedIn, loading: state.base.loading };
}

export default compose(withTranslation(), connect(mapStateToProps, null))(LoginModal);
export default {
    "packageh1": "Bilgi Notu",
    "packageh2": "İlanınızı aşağıdaki uygun paketlerden birini seçerek yayınlayabilirsiniz. * İLK SAYFADA ÖNE ÇIKMA özellikli paketlerle aradığınız elemanı daha çabuk bulabilirsiniz.(FTSO ibareli olanlar, sadece Fethiye Ticaret ve Sanayi Odası Üyelerine Özel Paketlerdir.)",
    "packageh3": "Önceden tanımlanmış/satın aldığınız paket kredisini kullanın",
    "packageh4": "Teşekkür ederiz. İlanınız oluşturuldu",
    "packageh5": "İlanınız kontrol edildikten sonra yayına alınacaktır. Yardıma ihtiyacınız olursa lütfen destek@fethiyekariyer.com adresine e-posta gönderin",
    "publish": "Yayında",
    "draft": "Taslak",
    "awaiting-payment": "Ödeme Bekleniyor",
    "auto_draft": "Otomatik Taslak",
    "inherit": "Devralma",
    "pending": "Bekleme",
    "wc_completed": "Wc Tamamlandı",
    "wc_on_hold": "Wc Beklemede",
    "areyousuretodeletethistask": "Bu kaydı silmek istediğinizden emin misiniz?",
    "businesscredittobepublished": "Yayınlanacak iş kredisi	",
    "adboostcredit": "İlanı öne çıkarma kredisi	",
    "viewcandidateCVs": "Aday CV'lerini görüntüleme",
    "ordernumber": "Sipariş Numarası",
    "duration": "Süre",
    "status": "Durum",
    "salartype_type_1": "Maaş Belirleyin",
    "salartype_type_2": "Aylık",
    "salartype_negotiable": "Görüşmede Belirlenecek",
    "appliedpostings": "Başvurulan İlanlar",
    "favoritejobs": "Favori İlanlar",
    "followedcompanies": "Takip Edilen Firmalar",
    "cancompaniesseeyou": "Firmalar Sizi Görsün mü?",
    "generalinfo": "Genel Bilgi",
    "jobcount": "İş Sayısı",
    "jobtitle": "İş Ünvanı",
    "viewscount": "Görüntülenme Sayısı",
    "companydescription": "Firma Açıklaması",
    "resumecoverletter": "Başvuru Ön Yazısı",
    "education": "Eğitim",
    "academy": "Okul Adı/Bölüm",
    "workexperience": "İş Deneyimi",
    "expertise": "Uzmanlık",
    "foreignlanguage": "Yabancı Dil",
    "iwanttofindthebesttalent": "En iyi adayı bulmak istiyorum",
    "iwanttofindajob": "İş bulmak istiyorum",
    "candidate": "Aday",
    "registertype": "Üyelik Türü",
    "showad": "İlanı Göster",
    "invoiceinformation": "Fatura Bilgileri",
    "country": "Ülke",
    "notdisplayed": "Görüntülenmedi",
    "viewed": "Görüntülendi",
    "decline": "Reddedildi",
    "texadmin": "Vergi Dairesi",
    "taxnumber": "Vergi Numarası",
    "landphone": "Sabit Telefon",
    "educationalstatus": "Eğitim Durumu",
    "placeofbirth": "Doğum Yeri",
    "nationality": "Uyruğu",
    "maritalstatus": "Medeni Hali",
    "militarystatus": "Askerlik Durumu",
    "registrationnumber": "İşveren Sicil No",
    "fethiye-tso-uyesimisiniz": "Fethiye TSO Üyesi misiniz?",

}

import layoutLangs from './layout/trTR';
import homeLangs from './home/trTR';
import searchLangs from './search/trTR';
import jobSeekersLangs from './job_seekers/trTR';
import companiesLangs from './companies/trTR';
import askedsLangs from './asked/trTR';
import profilesLangs from './profiles/trTR';
import termsLangs from './terms/trTR';

export default {
    ...layoutLangs,
    ...homeLangs,
    ...searchLangs,
    ...jobSeekersLangs,
    ...companiesLangs,
    ...askedsLangs,
    ...profilesLangs,
    ...termsLangs,
    "english": "English",
    "turkish": "Türkçe",
    "russian": "Русский",
    "fethiyegucbirligi": "FETHİYE GÜÇ BİRLİĞİ",
    "fethiyegucbirligifullname": "Fethiye Güç Birliği Turizm Tanıtım ltd şti.",
    "login": "Giriş Yap",
    "signup": "Kaydol",
    "signout": "Çıkış Yap",
    "website": "Website",
    "home": "Ana Sayfa",
    "corporate": "Kurumsal",
    "examservices": "Sınav Hizmetleri",
    "legalresponsibilitiesandincentives": "Yasal Sorumluluklar ve Teşvikleri",
    "nationalqualifications": "Ulusal Yeterlilikler",
    "addressfethiye": "Taşyaka Mahallesi 144. Sk No:1/A Daire 4-Daire 2 Fethiye /MUĞLA",
    "phonefethiye": "0530 954 10 70",
    "faxfethiye": "0252 612 84 81",
    "emailfethiye": "destek@fethiyekariyer.com",
    "frequentlyaskedquestions": "Sıkça Sorulan Sorular",
    "application": "Başvuru",
    "name": "Adı",
    "surname": "Soyadı",
    "message": "İleti",
    "submit": "Gönder",
    "reset": "Sıfırla",
    "search": "Ara",
    "filter": "Filtrele",
    "acceptmessage": "Gönderilen verilerimin toplandığını ve saklandığını kabul ediyorum.",
    "isrequired": "Gereklidir",
    "accept": "Onaylandı",
    "contactform": "İletişim Formu",
    "contact": "İletişim",
    "phone": "Telefon",
    "email": "E-Posta",
    "address": "Adres",
    "location": "Lokasyon",
    "subscribe": "Bize Abone Ol",
    "send": "Gönder",
    "username": "Kullanıcı Adı",
    "password": "Şifre",
    "remember": "Beni Hatırla",
    "loginform": "Giriş Formu",
    "userinformation": "Kullanıcı Bilgileri",
    "firstname": "Ad",
    "lastname": "Soyad",
    "confirmpassword": "Şireyi Doğrula",
    "intro": "Tatınım",
    "gender": "Cinsiyet",
    "agreement": "Sözleşmeyi Okudum",
    "register": "Kayıt Ol",
    "registrationconfirmationsuccessful": "Kayıt İşleminiz Başarıyla Gerçekleştirilmiştir!",
    "shouldacceptagreement": "Anlaşmayı Kabul Etmelisiz",
    "pleaseconfirmyourpassword": "Lütfen şifrenizi doğrulayınız!",
    "thetwopasswordsthatyouentereddonotmatch": "Girdiğiniz iki şifre eşleşmiyor!",
    "theinputisnotvalidemail": "Girdiğiniz E-Posta geçerli değil",
    "registrationform": "Kayıt Ekranı",
    "male": "Erkek",
    "female": "Kadın",
    "other": "Diğer",
    "dublicateusername": "Bu kullanıcı adı zaten mevcut!",
    "dublicatemailaddress": "Kullanıcı Adı veya Email Adresi Zaten Sistemde Mevcuttur",
    "iforgotmypassport": "Şifremi Unuttum",
    "areyousuretoresetpassword": "Şifrenizi sıfırlamak istediğinizden emin misiniz",
    "yourpasswordresethasbeensuccessful": "Parola sıfırlama işleminiz başarıyla gerçekleştirilmiştir",
    "pleaseinputyouremail": "Lütfen e-posta adresinizi veya kullanıcı adınızı giriniz!",
    "pleasecheckyouremail": "Lütfen e-posta adresinizi kontrol ediniz!",
    "usernameorpasswordnotfound": "Kullanıcı adı veya şifre bulunamadı!",
    "usernameormailnotfound": "Kullanıcı adı veya e-posta adresi bulunamadı!",
    "userisnotactive": "Kullanıcı aktif değil!.Onay için lütfen mail adresini kontrol ediniz!",
    "procesessissuccessful": "İşlem başarılı",
    "userlanguage": "Kullanıcı Dili",
    "undefined": "Tanımsız",
    "totalitems": "Toplam Kayıt Sayısı",
    "views": "Görüntülenme",
    "view": "Görüntüle",
    "showonmap": "Haritada Görüntüle",
    "bolum": "Bölüm",
    "askerlik-durumu": "Askerlik Durumu",
    "jobstatus": "İş Durumu",
    "kariyer-seviyesi": "Kariyer Seviyesi",
    "deneyim": "Deneyim",
    "pozisyon": "Meslek",
    "sector": "Sektör",
    "cinsiyet": "Cinsiyet",
    "yas-araligi": "Yas Aralığı",
    "surucu-belgesi": "Sürücü Belgesi",
    "jobdescription": "İşin Tanımı",
    "addfavorite": "Favorilere Ekle",
    "applyforjob": "İşe Başvur",
    "contactcompany": "Firmayla İletişime Geç",
    "clickformore": "Daha fazlası için tıklayınız...",
    "releasedate": "Yayınlanma Tarihi",
    "last_one_hour": "Son bir saat",
    "last_24_hours": "Son 24 saat",
    "last_7_days": "Son 7 gün",
    "last_14_days": "Son 14 gün",
    "last_30_days": "Son 30 gün",
    "all": "Hepsi",
    "applyfilter": "Filtreyi Uygula",
    "clearfilter": "Temizle",
    "description": "Açıklama",
    "header": "Başlık",
    "startDate": "Başlama Tarihi",
    "endDate": "Bitiş Tarihi",
    "exist": "Mevcut",
    "save": "Kaydet",
    "otherinformation": "Diğer Bilgiler",
    "companyinformation": "Firma Bilgileri",
    "personalinformation": "Kişi Bilgileri",
    "socialmediainformation": "Sosyal Medya Bilgiler",
    "addressinformation": "Adres Bilgileri",
    "postanewad": "Yeni İlan Yayınla",
    "admanagement": "İlanları Yönet",
    "applicationsmade": "İlana Yapılan Başvurular",
    "registeredcandidates": "Kayıtlı Adaylar",
    "packages": "Paketler",
    "following": "Takip Eden",
    "myfavoritejobs": "Favori İş İlanlarım",
    "companiesifollow": "Takip Ettiğim Firmalar",
    "jobalert": "İş Uyarıları",
    "pleasesignin": "Lütfen Giriş Yapınız!",
    "createad": "İlan Oluştur",
    "packageselectionandpayment": "Paket Seçimi ve Ödeme",
    "approve": "Onayla",
    "general": "Genel",
    "title": "Başlık",
    "content": "İlan Açıklaması",
    "jobtype": "Çalışma Şekli",
    "salary": "Maaş",
    "salarytype": "Maaş Şekli",
    "jobminsalary": "En Düşük Maaş",
    "jobmaxsalary": "En Yüksek Maaş",
    "state": "İl",
    "city": "İlçe",
    "packagename": "Paket Adı",
    "packagecontent": "Paket İçeriği",
    "packageduration": "Paket Süresi",
    "adduration": "İlan Yayın Süresi",
    "price": "Ücreti",
    "alljobcount": "İlan Hakkı",
    "favoritejobcount": "Öne Çıkarma Sayısı",
    "years": "Yıl",
    "days": "Gün",
    "next": "Sonraki",
    "previous": "Önceki",
    "please!selectapackage": "Lütfen! Bir Paket Seçiniz",
    "applicationcount": "Başvuru Sayısı",
    "total": "Toplam",
    "used": "Kullanılan",
    "remaining": "Kalan",
    "paiddate": "Satınalma Tarihi",
    "expiredate": "Bitiş Tarihi",
    "active": "Aktif",
    "passive": "Pasif,",
    "yes": "Evet",
    "no": "Hayır",
    "add": "Ekle",
    "company": "Firma",
    "person": "Aday",
    "admin": "Admin",
    "changeuserrole": "Rol Değiştir",
    "userrolename": "Kullanıcı Rol Adı",
    "percentage": "Yüzde",
    "controlpanel": "Yönetim Paneli",
    "postauthor": "İlan Sahibi",
    "postdate": "İlan Tarihi",
    "infocard": "Bilgi Kartı",
    "delete": "Sil",
    "edit": "Düzenle",
    "pleasesearch": "Lütfen Arama Yapınız!",
    "employer": "İş Veren",
    "error": "Hata",
    "youarenotauthorized": "İlan vermek, Aday Havuzundan yararlanmak , İlanlarınızı ve Başvuruları Yönetmek için Kayıtlı Firma olmanız  Giriş yapmanız gerekmektedir.",
    "youarenotauthorized2": " Firma Üyemizseniz hemen Üye Girişi yapabilir, üye değilseniz Şimdi Kaydol bölümünden FİRMA ÜYELİĞİ’nizi başlatabilirsiniz.",
    "youarenotauthorized3": "Bu Sayfayada Yetkili Değilsiniz! Sadece Firmalar Bu Sayfada İşlem Yapabilir",
    "actions": "İşlemler",
    "areyousuretodeletethistask": "Bu Kaydı Silmek İstediğinize Emin Misiniz?",
    "true": "Aktif",
    "false": "Pasif",
    "createdate": "Oluşturma Tarihi",
    "date": "Tarih",
    "wc-completed": "Sipariş Onaylandı",
    "wc-on-hold": "Sipariş Onay Bekliyor",
    "companyname": "Firma Ünvanı",
    "confirmation": "Onaylama",
    "confirmtheorder": "Siparişi Onayla",
    "postthejob": "İşi Yayınla",
    "warning": "Uyarı",
    "tobedeterminedatthemeeting": "Görüşmede belirlenecek",
    "rolename": "Rol Adı",
    "reject": "Reddet",
    "upload": "Yükle",
    "ads": "İlanlar",
    "displayname": "Görünen İsim",
    "jobapplied": "İlan Başvuruları",
    "pleaselogin": "Bu İşlem İçin Giriş Yapınız!",
    "pleaseloginagain": "Lütfen tekrar giriş yapınız!",
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Dropdown, Menu } from 'antd';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import { withTranslation } from 'react-i18next';
import { isMobile } from "react-device-detect";


const NavMenu = (props) => {
    const [state, setState] = useState({ extraMenuItems: [] });

    useEffect(() => {
        const userRoles = props.user?.userData?.UserRoles || [];
        let extraMenuItems = [];
        extraMenuItems.push(<Menu.Item key="1"> <Link to="/">{i18n.t("home")}</Link></Menu.Item>);
        if (userRoles.find(f => f.RoleName === "Admin")) {
            extraMenuItems.push(<Menu.Item key="adminPanel"><Link to="/admin-page">{i18n.t("controlpanel")}</Link></Menu.Item>)
        }
        setState({ ...state, extraMenuItems });
    }, [props.auth, props.loggedIn])

    const getMenuItems = () => {
        const menuItems = [...state.extraMenuItems];
        menuItems.push(<Menu.Item key="2"> <Link to="/jobpostings-page">{i18n.t("ads")}</Link></Menu.Item>);
        menuItems.push(<Menu.Item key="3"> <Link to="/employer-page">{i18n.t("employer")}</Link></Menu.Item>);
        menuItems.push(<Menu.Item key="4"> <Link to="/contact-page">{i18n.t("contact")}</Link></Menu.Item>);

        //menuItems.push(<Menu.Item key="2"> <Link to="/jobpostings-page">{i18n.t("jobpostings")}</Link></Menu.Item>);
        //menuItems.push(<Menu.Item key="3"> <Link to="/jobseekers-page">{i18n.t("jobseekers")}</Link></Menu.Item>);
        //menuItems.push(<Menu.Item key="4"> <Link to="/companies-page">{i18n.t("companies")}</Link></Menu.Item>);
        //menuItems.push(<Menu.Item key="5"> <Link to="/cvpool-page">{i18n.t("CVPool")}</Link></Menu.Item>);
        //menuItems.push(<Menu.Item key="6"> <Link to="/adpackages-page">{i18n.t("adpackages")}</Link></Menu.Item>);
        //menuItems.push(<Menu.Item key="7"> <Link to="/frequentlyaskedquestions-page">{i18n.t("frequentlyaskedquestions")}</Link></Menu.Item>);

        //if (isMobile) {
        //    return <Menu.SubMenu key="sub1" icon={<MenuUnfoldOutlined style={{ fontSize: "32px" }} />} title="">{menuItems}</Menu.SubMenu>;
        //} else {
        return menuItems;
        //}
    }

    if (isMobile) {
        const menuProps = {
            style: { minWidth: "25vw" },
            mode: "inline",
            theme: "dark"

        }
        const menu = <Menu {...menuProps}> {getMenuItems()}</Menu>
        return (
            <Dropdown overlay={menu} trigger={['click']}>
                <MenuUnfoldOutlined style={{ fontSize: 48, color: "white", paddingTop: "10px" }} />
            </Dropdown>
        );
    } else {
        return (
            <Menu mode={"horizontal"} theme="dark" className={"layout-navmenu"} >
                {getMenuItems()}
            </Menu>
        );
    }
};

const mapStateToProps = (state) => {
    const { user, loggedIn } = state.authentication;
    return { user, loggedIn };
}

export default compose(withTranslation(), connect(mapStateToProps))(NavMenu);
export const NewAdsList = {
    "pageList": [
        {
            "id": 272081,
            "userId": 50281,
            "title": "ÇAMAŞIRHANE",
            "postDate": "2022-02-23T14:22:01",
            "postDateGMT": "2022-02-23T11:22:01",
            "postStatus": "publish",
            "userDisplayName": "CLUB TUANA",
            "profilePictureUrl": "https://www.fethiyekariyer.com/wp-content/uploads/jobsearch-user-files/face-logo.png",
            "otherInfos": {
                "jobtype": "Dönemlik",
                "sector": "Turizm"
            },
            "metaList": [
                {
                    "key": "job_salary",
                    "value": ""
                },
                {
                    "key": "job_max_salary",
                    "value": ""
                },
                {
                    "key": "jobsearch_field_location_location2",
                    "value": "Muğla"
                },
                {
                    "key": "jobsearch_field_location_location3",
                    "value": "Fethiye"
                }
            ]
        },
        {
            "id": 248791,
            "userId": 50281,
            "title": "HAUSEKEEPING DEPARTMANI",
            "postDate": "2022-02-23T14:16:05",
            "postDateGMT": "2022-02-23T11:16:05",
            "postStatus": "publish",
            "userDisplayName": "CLUB TUANA",
            "profilePictureUrl": "https://www.fethiyekariyer.com/wp-content/uploads/jobsearch-user-files/face-logo.png",
            "otherInfos": {
                "jobtype": "Yarı Zamanlı",
                "sector": "Turizm"
            },
            "metaList": [
                {
                    "key": "job_salary",
                    "value": ""
                },
                {
                    "key": "job_max_salary",
                    "value": ""
                },
                {
                    "key": "jobsearch_field_location_location2",
                    "value": "Muğla"
                },
                {
                    "key": "jobsearch_field_location_location3",
                    "value": ""
                }
            ]
        },
        {
            "id": 248788,
            "userId": 50281,
            "title": "SERVİS BAR PERSONELİ",
            "postDate": "2022-02-23T14:08:43",
            "postDateGMT": "2022-02-23T11:08:43",
            "postStatus": "publish",
            "userDisplayName": "CLUB TUANA",
            "profilePictureUrl": "https://www.fethiyekariyer.com/wp-content/uploads/jobsearch-user-files/face-logo.png",
            "otherInfos": {
                "jobtype": "Yarı Zamanlı",
                "sector": "Turizm"
            },
            "metaList": [
                {
                    "key": "job_salary",
                    "value": ""
                },
                {
                    "key": "job_max_salary",
                    "value": ""
                },
                {
                    "key": "jobsearch_field_location_location2",
                    "value": "Muğla"
                },
                {
                    "key": "jobsearch_field_location_location3",
                    "value": "0"
                }
            ]
        },
        {
            "id": 242423,
            "userId": 50281,
            "title": "RESEPSİYONİST/REZARVASYON SORUMLUSU",
            "postDate": "2022-02-23T14:05:41",
            "postDateGMT": "2022-02-23T11:05:41",
            "postStatus": "publish",
            "userDisplayName": "CLUB TUANA",
            "profilePictureUrl": "https://www.fethiyekariyer.com/wp-content/uploads/jobsearch-user-files/face-logo.png",
            "otherInfos": {
                "jobtype": "Dönemlik",
                "sector": "Turizm"
            },
            "metaList": [
                {
                    "key": "job_salary",
                    "value": ""
                },
                {
                    "key": "job_max_salary",
                    "value": ""
                },
                {
                    "key": "jobsearch_field_location_location2",
                    "value": "Muğla"
                },
                {
                    "key": "jobsearch_field_location_location3",
                    "value": "Fethiye"
                }
            ]
        },
        {
            "id": 248794,
            "userId": 50281,
            "title": "MUTFAK",
            "postDate": "2022-02-23T13:51:34",
            "postDateGMT": "2022-02-23T10:51:34",
            "postStatus": "publish",
            "userDisplayName": "CLUB TUANA",
            "profilePictureUrl": "https://www.fethiyekariyer.com/wp-content/uploads/jobsearch-user-files/face-logo.png",
            "otherInfos": {
                "jobtype": "Yarı Zamanlı",
                "sector": "Turizm"
            },
            "metaList": [
                {
                    "key": "job_salary",
                    "value": ""
                },
                {
                    "key": "job_max_salary",
                    "value": ""
                },
                {
                    "key": "jobsearch_field_location_location2",
                    "value": "Muğla"
                },
                {
                    "key": "jobsearch_field_location_location3",
                    "value": ""
                }
            ]
        },
        {
            "id": 272070,
            "userId": 50281,
            "title": "GÜVENLİK GÖREVLİSİ",
            "postDate": "2022-02-23T13:47:11",
            "postDateGMT": "2022-02-23T10:47:11",
            "postStatus": "publish",
            "userDisplayName": "CLUB TUANA",
            "profilePictureUrl": "https://www.fethiyekariyer.com/wp-content/uploads/jobsearch-user-files/face-logo.png",
            "otherInfos": {
                "jobtype": "Dönemlik",
                "sector": "Turizm"
            },
            "metaList": [
                {
                    "key": "job_salary",
                    "value": ""
                },
                {
                    "key": "job_max_salary",
                    "value": ""
                },
                {
                    "key": "jobsearch_field_location_location2",
                    "value": "Muğla"
                },
                {
                    "key": "jobsearch_field_location_location3",
                    "value": "Fethiye"
                }
            ]
        },
        {
            "id": 272063,
            "userId": 47692,
            "title": "OKUL ÖNCESİ ÖĞRETMENİ",
            "postDate": "2022-02-23T13:25:32",
            "postDateGMT": "2022-02-23T10:25:32",
            "postStatus": "publish",
            "userDisplayName": "KÜÇÜK ŞEYLER EĞİTİM DANIŞMANLIK İNŞAAT GIDA TURİZM SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
            "profilePictureUrl": "http://www.fethiyekariyer.com/employer/kucuk-seyler-egitim-danismanlik-insaat-gida-turizm-sanayi-ve-ticaret-limited-sirketi/1a676360-e49a-4440-905a-736fb21accdb-jpg/",
            "otherInfos": {
                "jobtype": "Tam Zamanlı",
                "sector": "Eğitim Hizmetleri"
            },
            "metaList": [
                {
                    "key": "job_salary",
                    "value": ""
                },
                {
                    "key": "job_max_salary",
                    "value": ""
                },
                {
                    "key": "jobsearch_field_location_location2",
                    "value": "Muğla"
                },
                {
                    "key": "jobsearch_field_location_location3",
                    "value": "Fethiye"
                }
            ]
        },
        {
            "id": 272042,
            "userId": 47318,
            "title": "Sea Breeze Otel’de Çalıştırılmak üzere İngilizce bilen Resepsiyonist  alınacaktır.",
            "postDate": "2022-02-23T12:57:02",
            "postDateGMT": "2022-02-23T09:57:02",
            "postStatus": "publish",
            "userDisplayName": "EZMEN TURİZM SEA BREEZE HOTEL &#8211; EZMEN TURİZM SANAYİ VE TİCARET A.Ş.",
            "profilePictureUrl": "http://www.fethiyekariyer.com/employer/ezmen-turizm-sea-breeze-hotel-ezmen-turizm-sanayi-ve-ticaret-a-s/ba7cf28f-0ac5-4d71-8640-c8c1fdc61930-jpg/",
            "otherInfos": {
                "jobtype": "Dönemlik",
                "sector": "Turizm"
            },
            "metaList": [
                {
                    "key": "job_salary",
                    "value": "1"
                },
                {
                    "key": "job_max_salary",
                    "value": "2"
                },
                {
                    "key": "jobsearch_field_location_location2",
                    "value": "Muğla"
                },
                {
                    "key": "jobsearch_field_location_location3",
                    "value": "Fethiye"
                }
            ]
        },
        {
            "id": 272031,
            "userId": 47318,
            "title": "Sea Breeze Otel'de 12 ay Çalıştırılmak Teknik Elaman Alınacaktır",
            "postDate": "2022-02-23T12:46:55",
            "postDateGMT": "2022-02-23T09:46:55",
            "postStatus": "publish",
            "userDisplayName": "EZMEN TURİZM SEA BREEZE HOTEL &#8211; EZMEN TURİZM SANAYİ VE TİCARET A.Ş.",
            "profilePictureUrl": "http://www.fethiyekariyer.com/employer/ezmen-turizm-sea-breeze-hotel-ezmen-turizm-sanayi-ve-ticaret-a-s/ba7cf28f-0ac5-4d71-8640-c8c1fdc61930-jpg/",
            "otherInfos": {
                "jobtype": "Tam Zamanlı",
                "sector": "Turizm"
            },
            "metaList": [
                {
                    "key": "job_salary",
                    "value": "1"
                },
                {
                    "key": "job_max_salary",
                    "value": "2"
                },
                {
                    "key": "jobsearch_field_location_location2",
                    "value": "Muğla"
                },
                {
                    "key": "jobsearch_field_location_location3",
                    "value": "Fethiye"
                }
            ]
        },
        {
            "id": 272017,
            "userId": 47945,
            "title": "BAYAN ELEMAN",
            "postDate": "2022-02-23T11:52:26",
            "postDateGMT": "2022-02-23T08:52:26",
            "postStatus": "publish",
            "userDisplayName": "OSMAN AVCI PRESTİJ İNŞAAT EMLAK",
            "profilePictureUrl": "https://www.fethiyekariyer.com/wp-content/uploads/2020/02/precious-stories.jpg",
            "otherInfos": {
                "jobtype": "Dönemlik",
                "sector": "Hizmet"
            },
            "metaList": [
                {
                    "key": "job_salary",
                    "value": "4250"
                },
                {
                    "key": "job_max_salary",
                    "value": "5000"
                },
                {
                    "key": "jobsearch_field_location_location2",
                    "value": "Muğla"
                },
                {
                    "key": "jobsearch_field_location_location3",
                    "value": "Fethiye"
                }
            ]
        }
    ],
    "totalCount": 1451
}

export const postData = {
    "id": 271788,
    "userId": 48035,
    "title": "MUTFAK PERSONELİ",
    "content": "Hisarönü ofisimizde çalışmak üzere 30 – 50 yaş aralığında  yardımcı bayan personel aranmaktadır.\r\n<ul>\r\n \t<li>ulaşım,</li>\r\n \t<li>yemek,</li>\r\n \t<li>sigorta tarafımızdan karşılanacaktır.</li>\r\n</ul>\r\nÇalışma şartları ve maaş karşılıklı konuşularak belirlenecektir.\r\n\r\n10:00 ile 17:00 saatleri arasında 0 533 631 31 56 ile görüşebilirsiniz\r\n\r\nİş başlangıç tarihi 15 Mart 2022’dir.\r\n\r\nSadece telefon ile yapılan başvurular işleme alınacaktır.",
    "postDate": "2022-02-21T16:26:46",
    "postDateGMT": "2022-02-21T13:26:46",
    "postStatus": "publish",
    "userDisplayName": "PAPİRUS TURİZM SEYAHAT ACENTASI &#8211; TTI TURİZM İNŞAAT EMLAK REKLAM TİCARET LİMİTED ŞİRKETİ",
    "profilePictureUrl": "http://www.fethiyekariyer.com/wp-content/uploads/2020/02/d6598f2d-508e-411e-aaa2-22f6502bb32b.jpg",
    "otherInfos": {
        "jobtype": "Tam Zamanlı",
        "sector": "Turizm"
    },
    "metaList": [
        {
            "key": "job_title",
            "value": "MUTFAK PERSONELİ"
        },
        {
            "key": "job_detail",
            "value": "Hisarönü ofisimizde çalışmak üzere 30 – 50 yaş aralığında  yardımcı bayan personel aranmaktadır.\r\n<ul>\r\n \t<li>ulaşım,</li>\r\n \t<li>yemek,</li>\r\n \t<li>sigorta tarafımızdan karşılanacaktır.</li>\r\n</ul>\r\nÇalışma şartları ve maaş karşılıklı konuşularak belirlenecektir.\r\n\r\n10:00 ile 17:00 saatleri arasında 0 533 631 31 56 ile görüşebilirsiniz\r\n\r\nİş başlangıç tarihi 15 Mart 2022’dir.\r\n\r\nSadece telefon ile yapılan başvurular işleme alınacaktır."
        },
        {
            "key": "job_sector",
            "value": "61"
        },
        {
            "key": "job_type",
            "value": "33"
        },
        {
            "key": "job_apply_type",
            "value": "internal"
        },
        {
            "key": "job_salary_type",
            "value": "negotiable"
        },
        {
            "key": "job_salary",
            "value": ""
        },
        {
            "key": "job_max_salary",
            "value": ""
        },
        {
            "key": "bolum",
            "value": "Mutfak"
        },
        {
            "key": "kariyer-seviyesi",
            "value": "Diğer"
        },
        {
            "key": "deneyim",
            "value": "2 Yıl"
        },
        {
            "key": "pozisyon",
            "value": "Aşçı"
        },
        {
            "key": "cinsiyet",
            "value": "Kadın"
        },
        {
            "key": "yas-araligi",
            "value": "30-50 aralığı"
        },
        {
            "key": "surucu-belgesi",
            "value": "a:1:{i:0;s:3:\"Yok\";}"
        },
        {
            "key": "jobsearch_field_location_location1",
            "value": "Turkey"
        },
        {
            "key": "jobsearch_field_location_location2",
            "value": "Muğla"
        },
        {
            "key": "jobsearch_field_location_location3",
            "value": ""
        },
        {
            "key": "jobsearch_location_old_postalcode",
            "value": ""
        },
        {
            "key": "jobsearch_field_location_postalcode",
            "value": ""
        },
        {
            "key": "jobsearch_field_location_address",
            "value": "Hıdırkuyusu Cad. No:11/A  Hisarönü Mah. Fethiye/Muğla"
        },
        {
            "key": "jobsearch_field_location_lat",
            "value": "37.090240"
        },
        {
            "key": "jobsearch_field_location_lng",
            "value": "-95.712891"
        },
        {
            "key": "jobsearch_field_location_zoom",
            "value": "12"
        },
        {
            "key": "user_job_posting",
            "value": "1"
        },
        {
            "key": "terms_cond_check",
            "value": "on"
        },
        {
            "key": "jobsearch_field_job_featured",
            "value": ""
        },
        {
            "key": "jobsearch_field_job_posted_by",
            "value": "54503"
        },
        {
            "key": "jobsearch_field_job_filled",
            "value": ""
        },
        {
            "key": "jobsearch_field_job_publish_date",
            "value": "1645460807"
        },
        {
            "key": "jobsearch_field_job_apply_type",
            "value": "internal"
        },
        {
            "key": "jobsearch_field_job_salary",
            "value": ""
        },
        {
            "key": "jobsearch_field_job_max_salary",
            "value": ""
        },
        {
            "key": "jobsearch_field_job_salary_type",
            "value": "negotiable"
        },
        {
            "key": "jobsearch_field_job_attachment_files",
            "value": "a:0:{}"
        },
        {
            "key": "make_it_to_feature",
            "value": ""
        },
        {
            "key": "job_package_new",
            "value": "138480"
        },
        {
            "key": "make_job_feature",
            "value": "no"
        },
        {
            "key": "user_job_package_chose",
            "value": "1"
        },
        {
            "key": "attach_packages_array",
            "value": "a:1:{i:0;a:14:{s:12:\"package_name\";s:43:\"FTSO Üyeleri İçin Ücretsiz İlan Paketi\";s:20:\"package_charges_type\";s:4:\"free\";s:12:\"package_type\";s:13:\"emp_allin_one\";s:13:\"package_price\";i:0;s:19:\"package_expiry_time\";s:1:\"1\";s:24:\"package_expiry_time_unit\";s:5:\"years\";s:14:\"allin_num_jobs\";s:1:\"1\";s:15:\"allin_num_fjobs\";s:1:\"0\";s:20:\"allinjob_expiry_time\";s:2:\"30\";s:25:\"allinjob_expiry_time_unit\";s:4:\"days\";s:21:\"fall_cred_expiry_time\";s:1:\"0\";s:26:\"fall_cred_expiry_time_unit\";s:4:\"days\";s:13:\"allin_num_cvs\";s:7:\"1000000\";s:21:\"allinview_consume_cvs\";s:2:\"on\";}}"
        },
        {
            "key": "jobsearch_field_job_expiry_date",
            "value": "1648052876"
        },
        {
            "key": "jobsearch_job_single_exp_cron",
            "value": "yes"
        },
        {
            "key": "jobsearch_field_job_status",
            "value": "approved"
        },
        {
            "key": "jobsearch_job_is_under_review",
            "value": ""
        },
        {
            "key": "jobsearch_job_is_already_posted",
            "value": "yes"
        },
        {
            "key": "_edit_lock",
            "value": "1645450477:53229"
        },
        {
            "key": "_edit_last",
            "value": "53229"
        },
        {
            "key": "careerfy_field_job_post_detail_style",
            "value": ""
        },
        {
            "key": "_wpnonce",
            "value": "ebe97d859d"
        },
        {
            "key": "_wp_http_referer",
            "value": "/wp-admin/post.php?post=271788&action=edit"
        },
        {
            "key": "user_ID",
            "value": "53229"
        },
        {
            "key": "action",
            "value": "editpost"
        },
        {
            "key": "originalaction",
            "value": "editpost"
        },
        {
            "key": "post_author",
            "value": "48035"
        },
        {
            "key": "post_type",
            "value": "job"
        },
        {
            "key": "original_post_status",
            "value": "publish"
        },
        {
            "key": "referredby",
            "value": "https://www.fethiyekariyer.com/wp-admin/edit.php?post_type=job"
        },
        {
            "key": "_wp_original_http_referer",
            "value": "https://www.fethiyekariyer.com/wp-admin/edit.php?post_type=job"
        },
        {
            "key": "post_ID",
            "value": "271788"
        },
        {
            "key": "meta-box-order-nonce",
            "value": "b126f8ec53"
        },
        {
            "key": "closedpostboxesnonce",
            "value": "1aa0ad044e"
        },
        {
            "key": "original_post_title",
            "value": "MUTFAK PERSONELİ"
        },
        {
            "key": "post_title",
            "value": "MUTFAK PERSONELİ"
        },
        {
            "key": "samplepermalinknonce",
            "value": "e4091e6b1f"
        },
        {
            "key": "content",
            "value": "Hisarönü ofisimizde çalışmak üzere 30 – 50 yaş aralığında  yardımcı bayan personel aranmaktadır.\r\n<ul>\r\n \t<li>ulaşım,</li>\r\n \t<li>yemek,</li>\r\n \t<li>sigorta tarafımızdan karşılanacaktır.</li>\r\n</ul>\r\nÇalışma şartları ve maaş karşılıklı konuşularak belirlenecektir.\r\n\r\n10:00 ile 17:00 saatleri arasında 0 533 631 31 56 ile görüşebilirsiniz\r\n\r\nİş başlangıç tarihi 15 Mart 2022’dir.\r\n\r\nSadece telefon ile yapılan başvurular işleme alınacaktır."
        },
        {
            "key": "wp-preview",
            "value": ""
        },
        {
            "key": "hidden_post_status",
            "value": "publish"
        },
        {
            "key": "post_status",
            "value": "publish"
        },
        {
            "key": "hidden_post_password",
            "value": ""
        },
        {
            "key": "hidden_post_visibility",
            "value": "public"
        },
        {
            "key": "visibility",
            "value": "public"
        },
        {
            "key": "post_password",
            "value": ""
        },
        {
            "key": "jj",
            "value": "21"
        },
        {
            "key": "mm",
            "value": "02"
        },
        {
            "key": "aa",
            "value": "2022"
        },
        {
            "key": "hh",
            "value": "16"
        },
        {
            "key": "mn",
            "value": "26"
        },
        {
            "key": "ss",
            "value": "46"
        },
        {
            "key": "hidden_mm",
            "value": "02"
        },
        {
            "key": "cur_mm",
            "value": "02"
        },
        {
            "key": "hidden_jj",
            "value": "21"
        },
        {
            "key": "cur_jj",
            "value": "21"
        },
        {
            "key": "hidden_aa",
            "value": "2022"
        },
        {
            "key": "cur_aa",
            "value": "2022"
        },
        {
            "key": "hidden_hh",
            "value": "16"
        },
        {
            "key": "cur_hh",
            "value": "16"
        },
        {
            "key": "hidden_mn",
            "value": "26"
        },
        {
            "key": "cur_mn",
            "value": "30"
        },
        {
            "key": "original_publish",
            "value": "Güncelle"
        },
        {
            "key": "save",
            "value": "Güncelle"
        },
        {
            "key": "tax_input",
            "value": "a:3:{s:5:\"skill\";s:0:\"\";s:6:\"sector\";a:2:{i:0;s:1:\"0\";i:1;s:2:\"61\";}s:7:\"jobtype\";a:2:{i:0;s:1:\"0\";i:1;s:2:\"33\";}}"
        },
        {
            "key": "newtag",
            "value": "a:1:{s:5:\"skill\";s:0:\"\";}"
        },
        {
            "key": "excerpt",
            "value": ""
        },
        {
            "key": "newsector",
            "value": "Yeni Sektör Adı"
        },
        {
            "key": "newsector_parent",
            "value": "-1"
        },
        {
            "key": "_ajax_nonce-add-sector",
            "value": "c602951387"
        },
        {
            "key": "newjobtype",
            "value": "Yeni İş Türü Adı"
        },
        {
            "key": "newjobtype_parent",
            "value": "-1"
        },
        {
            "key": "_ajax_nonce-add-jobtype",
            "value": "e57fe26f22"
        },
        {
            "key": "_thumbnail_id",
            "value": "-1"
        },
        {
            "key": "post_name",
            "value": "mutfak-personeli-7"
        },
        {
            "key": "job_actexpiry_date",
            "value": "1648052876"
        },
        {
            "key": "jobsearch_field_job_feature_till",
            "value": ""
        },
        {
            "key": "cusjob_urgent_fbckend",
            "value": ""
        },
        {
            "key": "jobsearch_job_presnt_status",
            "value": "approved"
        },
        {
            "key": "jobsearch_field_map_height",
            "value": "250"
        },
        {
            "key": "jobsearch_field_marker_image",
            "value": ""
        },
        {
            "key": "post_mime_type",
            "value": ""
        },
        {
            "key": "ID",
            "value": "271788"
        },
        {
            "key": "post_content",
            "value": "Hisarönü ofisimizde çalışmak üzere 30 – 50 yaş aralığında  yardımcı bayan personel aranmaktadır.\r\n<ul>\r\n \t<li>ulaşım,</li>\r\n \t<li>yemek,</li>\r\n \t<li>sigorta tarafımızdan karşılanacaktır.</li>\r\n</ul>\r\nÇalışma şartları ve maaş karşılıklı konuşularak belirlenecektir.\r\n\r\n10:00 ile 17:00 saatleri arasında 0 533 631 31 56 ile görüşebilirsiniz\r\n\r\nİş başlangıç tarihi 15 Mart 2022’dir.\r\n\r\nSadece telefon ile yapılan başvurular işleme alınacaktır."
        },
        {
            "key": "post_excerpt",
            "value": ""
        },
        {
            "key": "comment_status",
            "value": "closed"
        },
        {
            "key": "ping_status",
            "value": "closed"
        },
        {
            "key": "jobsearch_field_package_exfield_title",
            "value": ""
        },
        {
            "key": "jobsearch_field_package_exfield_val",
            "value": ""
        },
        {
            "key": "jobsearch_field_job_employer_count_updated",
            "value": "yes"
        },
        {
            "key": "jobsearch_job_employer_status",
            "value": "approved"
        },
        {
            "key": "jobsearch_job_views_count",
            "value": "34"
        }
    ]
}

export const dummyDatas = {};

export const  resumeData = {
    "resume_cover_letter": "test 3",
    "education_academy": [
        "test",
        "test üniversite"
    ],
    "education_title": [
        "test eğitim 2",
        ""
    ],
    "education_description": [
        "test",
        "test açıklama"
    ],
    "education_start_date": [
        "2022-04-03",
        "2022-04-03"
    ],
    "education_end_date": [
        "2022-04-04",
        "2022-04-11"
    ],
    "experience_title": [
        "test iş deneyim"
    ],
    "experience_company": [
        "test iş deneyim firma "
    ],
    "experience_description": [
        "test iş deneyim açıklama"
    ],
    "experience_start_date": [
        "2022-04-03"
    ],
    "experience_end_date": [
        "2022-04-03"
    ],
    "skill_title": [
        "test uzmanlık"
    ],
    "skill_percentage": [
        "50"
    ],
    "lang_title": [
        "test yabancı dil"
    ],
    "lang_percentage": [
        "50"
    ],
    "lang_level": [
        "intermediate"
    ]
}
export default {
    "header-1": "An Important Step For Your Career Goal",
    "header-2": "Job Postings That Are Right For You Are Here",
    "header-3": "FEATURED ADVERTISEMENTS",
    "header-4.1": "We are being renewed in line with the opinions and suggestions from our members",
    "header-4": "Listings Waiting to Meet You Soon",    
    "hpg5": "FINDING A JOB IS NOW EASIER",
    "hpg5-1": "Register as a candidate, fill out your profile completely, so that employers can find you more easily. You too will be notified of a new posting immediately with the ADVERTISEMENT ALERTS that you will create for the search conditions you use while searching for a job.",
    "hpg6": "FIND TALENTS, FOCUS ON YOUR BUSINESS",
    "hpg6-1": "Create your employer registration, enter the company profile in detail, view the postings and applicants for the job postings in detail. Track the interviewees separately. It is now easier to find the talent you are looking for, you can even look at the Candidate Pool before posting. talent awaits you.",
}
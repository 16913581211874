export default {
    "footer-1": "Fethiye Power Union Education Consultancy Tourism Promotion Limited Company Private Employment Bureau has been operating with the document number 1205 in accordance with the decision of the Turkish Employment Agency dated 03/12/2020 and numbered 7333354 to operate as of 10/12/2020. Pursuant to Law No. 4904, it is forbidden to charge wages from job seekers. You can contact the following phone number for complaints. Turkish Employment Agency Muğla Provincial Directorate: 0(252) 214 8420 - 0(252) 214 8803 . Turkish Employment Agency Muğla-Fethiye Service Center: 0(252) 612 82 18",
    "footer-1.2": "Fethiye Power Union Education Consultancy Tourism Promotion Limited Company. Turkish Employment Agency Muğla Provincial Directorate: 0(252) 214 8420 - 0(252) 214 8803 . Turkish Employment Agency Muğla-Fethiye Service Center: 0(252) 612 82 18",
    "jobpostings": "Job Postings",
    "jobseekers": "Job Seekers",
    "companies": "Companies",
    "CVPool": "CV Pool",
    "adpackages": "Ad Packages",

    "technicalsupportline-1": "Technical Support Line",
    "technicalsupportline-2": "Mon - Friday 09:00 - 17:30",
    "candidateprocesses":"Candidate Processes",
    "companytransactions":"Company Transactions",
    "legalterms":"Legal Terms",
    "loginregistration":"Login / Registration",
    "postanad":"Post an ad",
    "postcvpackages":"Post / CV Packages",
    "sitetermsofuse":"Site Terms of Use",
    "warrantyrefundcancellation":"Warranty - Refund - Cancellation",
    "kvkklightingtext": "PDPL Lighting Text",

    "dashboard":"Dashboard",
    "profile":"Profile",
    "resume":"Resume",
    "postingsiappliedfor":"Postings I Applied For",
    "myfavoriteworks":"My Favorite Works",
    "companiesifollow":"Companies I Follow",
    "changemypassword":"Change My Password",
    "jobalerts":"Job Alerts",
}
import { callApi } from "./base.services";
const { apiUrl } = window['runConfig'];

export const fileService = { fileUpload, fileDownload, getCurrentUserProfilePicture, getFileRelativePath };

async function fileUpload({ fileList, documentType, userId }) {
    const formData = new FormData();
    fileList.forEach(file => {
        formData.append('files[]', file);
    });
    const params = { documentType };
    if (userId) {
        params.userId = userId;
    }
    const pathParams = new URLSearchParams(params).toString();
    const serviceName = `File/Upload?${pathParams}`;
    try {
        let response = await callApi(serviceName, { method: 'POST', data: formData, contentType: 'multipart' });
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}

async function fileDownload(pathname) {
    const serviceName = `File/DownloadFile?filePath=${pathname}`;
    try {
        let response = await callApi(serviceName, {});
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}

async function getCurrentUserProfilePicture(pathname) {
    const serviceName ="File/CurrentUserProfilePicture";
    try {
        let response = await callApi(serviceName, {});
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}

function getFileRelativePath(pathname) {
    const serviceName = `File/DownloadFile?filePath=${pathname}`;
    const relativePath = `${apiUrl}/${serviceName}`;
    return relativePath;
}
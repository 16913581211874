import { callApi } from "./base.services";

export const systemService = {
    getModuleCodeByName, getModuleCodeById, saveModuleCode, getModuleCodeValueById, saveModuleCodeValue,
    getTermDatas, getDropDownDatas, getDropDownAllDatas, getStates, getCities
};

async function getModuleCodeByName(codeName) {
    var serviceName = `System/getModuleCodeByName?name=${codeName}`;
    try {
        let response = await callApi(serviceName, {});
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}

async function getModuleCodeById(id) {
    var serviceName = `System/GetModuleCodeById?id=${id}`;
    try {
        let response = await callApi(serviceName, {});
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}

async function saveModuleCode(model) {
    var serviceName = "System/SaveModuleCode";
    try {
        let response = await callApi(serviceName, { method: "POST", data: JSON.stringify(model) });
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}

async function getModuleCodeValueById(id) {
    var serviceName = `System/GetModuleCodeValueById?id=${id}`;
    try {
        let response = await callApi(serviceName, {});
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}

async function saveModuleCodeValue(model) {
    var serviceName = "System/SaveModuleCodeValue";
    try {
        let response = await callApi(serviceName, { method: "POST", data: JSON.stringify(model) });
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}

async function getTermDatas(taxonomy) {
    var serviceName = `System/GetTermDatas?taxonomy=${taxonomy}`;
    try {
        let response = await callApi(serviceName, {});
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}

async function getDropDownDatas(drpName) {
    var serviceName = `System/GetDropDownDatas?drpName=${drpName}`;
    try {
        let response = await callApi(serviceName, {});
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}

async function getDropDownAllDatas() {
    var serviceName = `System/getDropDownAllDatas`;
    try {
        let response = await callApi(serviceName, {});
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}

async function getStates(stateId) {
    var serviceName = "System/GetStates";
    if (stateId) {
        serviceName += `?stateId=${stateId}`;
    }
    try {
        let response = await callApi(serviceName, {});
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}

async function getCities(stateId) {
    var serviceName = "System/GetCities";
    if (stateId) {
        serviceName += `?stateId=${stateId}`;
    }
    try {
        let response = await callApi(serviceName, {});
        return response;
    } catch (err) {
        return Promise.reject(err);
    }
}
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux"
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { Badge, Avatar, Image, Dropdown, Menu, Button, Space } from 'antd';
import { GlobalOutlined, LoginOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons';//HeatMapOutlined
import LanguageSwitcher from '../components/switchers/LanguageSwitcher';
import i18n from '../i18n';
import LoginModal from "../components/login/LoginModal";
import { fileService } from "../services/file.service";
import { authActions } from "../store/actions/auth.actions";
import { history } from '../utils/history';
import { isMobile } from "react-device-detect";

const HeaderAvatar = (props) => {
    const [state, setState] = useState({ profilePictureUrl: null })

    useEffect(() => {

        if (props.loggedIn) {
            fileService.getCurrentUserProfilePicture().then(result => {
                setState({ ...state, profilePictureUrl: result });
            });
        } else {
            setState({ ...state, profilePictureUrl: null })
        }

    }, [props.loggedIn, props.refresh])

    const memoziedLangSwitcher = useMemo(() => (
        <Space>
            <GlobalOutlined style={{ paddingLeft: "10px" }} />
            <Button type="text">
                <LanguageSwitcher />
            </Button>
        </Space>
    ), []);

    //const oldMenu = () => {
    //    return (<><Menu.Item>
    //        {i18n.t("dashboard")}
    //    </Menu.Item>
    //        <Menu.Divider />
    //        <Menu.Item>
    //            {i18n.t("resume")}
    //        </Menu.Item>
    //        <Menu.Divider />
    //        <Menu.Item>
    //            {i18n.t("postingsiappliedfor")}
    //        </Menu.Item>
    //        <Menu.Divider />
    //        <Menu.Item>
    //            {i18n.t("myfavoriteworks")}
    //        </Menu.Item>
    //        <Menu.Divider />
    //        <Menu.Item>
    //            {i18n.t("companiesifollow")}
    //        </Menu.Item>
    //        <Menu.Divider />
    //        <Menu.Item>
    //            {i18n.t("changemypassword")}
    //        </Menu.Item>
    //        <Menu.Divider />
    //        <Menu.Item>
    //            {i18n.t("jobalerts")}
    //        </Menu.Item></>);
    //}

    const onLogout = () => {
        props.logout();
        if (isMobile) {
            history.push({ pathname: `/` });
        } else {
            window.location.reload();
        }
    }

    const menu = (
        <Menu>
            {memoziedLangSwitcher}
            <Menu.Divider />
            <Menu.Item key={1} icon={props?.loggedIn ? <UserOutlined /> : <LoginOutlined />}>
                <LoginModal />
            </Menu.Item>
            {props?.loggedIn ? <>
                <Menu.Divider />
                <Menu.Item danger key={2} icon={<PoweroffOutlined />}>
                    <Button block type="text" onClick={onLogout}>
                        {i18n.t("signout")}
                    </Button>
                </Menu.Item>
            </> : null}
        </Menu>
    );

    let avatarProps = {};//|| "https://joeschmoe.io/api/v1/random"
    if (state.profilePictureUrl) {
        avatarProps = { src: < Image src={state.profilePictureUrl} preview={false} /> };
    }

    return (
        <Badge count={0} className="pointer">
            <Dropdown overlay={menu} trigger={['click']}>
                <Avatar size={isMobile ? 48 : 64} icon={<UserOutlined />} {...avatarProps} />
            </Dropdown>
        </Badge>
    );
}

const mapStateToProps = (state) => {
    return { loggedIn: state.authentication.loggedIn, refresh: state.authentication.refresh };
}

const mapDispatchToProps = {
    logout: authActions.logout,
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(HeaderAvatar);
export default {
    "faqh1": "Frequently Asked Questions",
    "faqh2": "Membership",
    "faqh3": "How can I delete my account?",
    "faqh3-1": "You can request deletion of your candidate or company membership by contacting us at the following contact information. Please note that if you terminate your membership, you must re-register in order to log in to the system and the companies in our database cannot access your CV. Even if you have canceled membership of the companies you have applied for before They may have saved your CV. E-Mail: support@fethiyekariyer.com Mobile: 0553 789 00 48 Tel: 0 252 614 0 365",
    "faqh4": "I can't remember my username and/or password, what can I do?",
    "faqh4-1": `If you have forgotten your username, password or e- mail information that you used while logging in to 'Fethiyekariyer.com', click on the "Forgot Password" link on the Candidate Login page.When you follow the instructions on the page that opens; Your user information will be sent to your registered e - mail address.`,
    "faqh5": "How can I change my password?",
    "faqh5-1": "After logging in, you can change your password on the page you access by clicking the Change Your Password link on the left menu on the My Account page.",
    "faqh6": "How can I change my registered email address?",
    "faqh6-1": 'After logging in, you can change your e-mail address by clicking "My Profile" from the menu on the left.',
    "faqh7": "How Do I Apply for Published Job Postings?",
    "faqh7-1": "Proin pretium eu purus id luctus. Aenean rhoncus felis neque, vitae ullamcorper magna pellentesque sed. Donec dapibus dapibus leo, vitae tristique sem. Nam leo magna, mollis ructrum, vestibulum.",

    "faqh8": "Candidate Membership",
    "faqh9": "How can I follow a company?",
    "faqh9-1": 'To follow a company, you can click the company name under the ad title and click the "follow" button on the incoming company page. You can see the ads you follow on the page opened from the "Companies You Follow" link in your management panel.',
    "faqh10": "How can I follow a posting?",
    "faqh10-1": 'To follow an ad, you can click the icon to the right of the ad. You can see the postings you follow on the page opened from the link of my favorite works in your management panel.',
    "faqh11": "I don't want to be in the CV pool, how can I do that?",
    "faqh11-1": 'If you only want your CV to be displayed by the company that owns the advertisement you applied for, Let Companies See You in the CV Pool in the My Profile section during or after registration? You can mark the option as no. In this case, your CV will not appear in the CV pool. It will only be displayed by the companies you have applied for.',
    "faqh12": "What do I need to do to be included in the CV pool?",
    "faqh12-1": 'If you want companies to find you by taking part in the CV pool, should the companies see you in the CV Pool in the My Profile section during or after registration? mark the option as yes. In this case, not only the companies you have applied for, but also other companies that are members of the site will be able to access your CV.',
    "faqh13": "How can I delete a previous application?",
    "faqh13-1": 'After logging in as a member, you can see your current applications on the screen that opens by clicking on the "Posts I have applied for" from the menu on the left. By clicking the "trash" icon to the right of the relevant application. Can you withdraw your application? Note: Even if you withdraw your application, the company representative may have downloaded your CV while you withdraw your application.',
    "faqh14": "Can the company I applied to see my application to other companies?",
    "faqh14-1": 'Companies you apply to cannot see your applications to other companies.',

    "faqh15": "CV Pool",
    "faqh16": "I can't see the candidates' contact information?",
    "faqh16-1": "Only the contact information of the candidates who have applied to you is shared with you. The candidates you reach from the CV pool cannot be shared with you in accordance with the KVK (Personal Data Protection) Law. In case there is a candidate you want to contact from the CV pool"
        + "You click the Save Candidate to List button on the candidate's profile detail page"
        + "You can see the candidates you have registered in the registered candidates section on the left in your administration panel."
        + "You can send a message to the candidate using the send message button under the candidate you have registered. The message you send is sent to the candidate's e-mail address registered on the site. We recommend that you also share your company information and contact information in the message content.",
    "faqh17": "I am a member of FTSO but why can't I access the cv pool?",
    "faqh17-1": "Your definitions for accessing the CV pool may be missing. To request access to the CV pool, you can send your company name and your e-mail address that you defined on fethiyekariyer.com to support@fethiyekariyer.com. Within 24 hours after your Fethiye TSO membership is checked You will be informed by defining the CV access right.",
    "faqh18": "Who can access the CV pool?",
    "faqh18-1": "Only FTSO members can access the CV pool",

}
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Form, Input, Select, Row, Col, Checkbox, Button, message, Switch } from 'antd';
import i18n from '../../i18n';
import { compose } from 'redux'
import { withTranslation } from 'react-i18next';
import { sha256 } from 'js-sha256';
import { authService } from '../../services/auth.services';
import { CountryCodeList } from '../../constants/country.code.constants';
import { GenderType, Languages } from '../../constants/system.constants';
import { baseActions } from '../../store/actions/base.actions';
import KVKK from '../terms/KVKK';

const { Option } = Select;

const formItemLayout = {
    labelCol: { sm: { span: 24 }, md: { span: 8 } },
    wrapperCol: { sm: { span: 24 }, md: { span: 16 } }
};
const tailFormItemLayout = {
    wrapperCol: {
        sm: { span: 16, offset: 8 },
        lg: { span: 20, offset: 4 }
    },
};

const RegistrationForm = (props) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        let { password } = values;
        password = sha256(password);
        const model = { ...values, password, confirm: password };
        props.setLoading(true);
        authService.registration(model).then(result => {
            message.success(i18n.t(result));
            props.closeModal();
            props.setLoading(false);
        }).catch(err => {
            props.setLoading(false);
            if (err.i18n) {
                message.error(i18n.t(err.i18n));
            } else {
                message.error(err.Errors);
            }
        });
    };

    useEffect(() => {
        // const promises = [];
        // promises.push(systemService.getCodeValueByName("gender"));
        // promises.push(systemService.getCodeValueByName("CountryCode"));
        // Promise.all(promises).then(results => {
        //     console.log(results);
        // });

    }, []);

    const prefixSelector = (
        <Form.Item rules={[{ required: true, },]} name="prefix" noStyle>
            <Select showSearch style={{ width: 100, }}  >
                {CountryCodeList.sort((a, b) => a.code - b.code).map(item =>
                    <Option key={item.code} value={item.dial_code}>
                        <Row gutter={8} justify="space-between">
                            <Col span={12}>{item.dial_code}</Col>
                            <Col span={12}>{item.code}</Col>
                        </Row>
                    </Option>
                )}
            </Select>
        </Form.Item>
    );

    const onSwitchChange = () => {

    }

    return (
        <Form {...formItemLayout} initialValues={{ prefix: "+90", isCompany: true }} form={form} onFinish={onFinish} scrollToFirstError  >
            <Row>
                <Col xs={24} sm={24} lg={12}>
                    <Form.Item
                        name="isCompany"
                        label={i18n.t("registertype")}
                        rules={[{ required: true, },]}
                    >
                        <Switch defaultChecked
                            onChange={onSwitchChange}
                            checkedChildren={`${i18n.t("company")}`}
                            unCheckedChildren={`${i18n.t("candidate")}`}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={12}>
                </Col>

                <Col xs={24} sm={24} lg={12}>
                    <Form.Item
                        name="firstName"
                        label={i18n.t("firstname")}
                        rules={[{ required: true, },]}
                    >
                        <Input placeholder={i18n.t("firstname")} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                    <Form.Item
                        name="lastName"
                        label={i18n.t("lastname")}
                        rules={[{ required: true, },]}
                    >
                        <Input placeholder={i18n.t("lastname")} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                    <Form.Item
                        name="userName"
                        label={i18n.t("username")}
                        rules={[{ required: true, whitespace: true, },]}
                    >
                        <Input placeholder={i18n.t("username")} autoComplete="user-name" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                    <Form.Item
                        name="email"
                        label={i18n.t("email")}
                        rules={[
                            {
                                type: 'email',
                                message: i18n.t("The input is not valid E-mail!"),
                            },
                            { required: true, },
                        ]}
                    >
                        <Input placeholder={i18n.t("email")} autoComplete="email" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                    <Form.Item
                        name="password"
                        label={i18n.t("password")}
                        rules={[{ required: true, },]}
                        hasFeedback
                    >
                        <Input.Password autoComplete="new-password" placeholder={i18n.t("password")} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                    <Form.Item
                        name="confirm"
                        label={i18n.t("confirmpassword")}
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: i18n.t("pleaseconfirmyourpassword"),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(i18n.t('thetwopasswordsthatyouentereddonotmatch')));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder={i18n.t("confirmpassword")} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                    <Form.Item
                        name="phone"
                        label={i18n.t("phone")}
                        rules={[{ required: true, },]}
                    >
                        <Input
                            addonBefore={prefixSelector}
                            style={{
                                width: '100%',
                            }}
                            placeholder={i18n.t("phone")}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                    <Form.Item name="gender" label={i18n.t("gender")}
                        rules={[{ required: true, },]}
                    >
                        <Select placeholder={i18n.t("gender")}>
                            {
                                GenderType.map(item => <Option key={item.code} value={item.code}>{i18n.t(item.title)}</Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                    <Form.Item name="lang" label={i18n.t("userlanguage")}
                        rules={[{ required: true, },]}
                    >
                        <Select placeholder={i18n.t("userlanguage")}>
                            {
                                Object.keys(Languages).map(key => <Option key={key} value={Languages[key]}>{i18n.t(key.toLowerCase())}</Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} lg={24}>
                    <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error(i18n.t('shouldacceptagreement'))),
                            },
                        ]}
                        {...tailFormItemLayout}
                    >
                        <Checkbox>
                            {/*<a href="/terms-page" target="_blank">{i18n.t("agreement")}</a>*/}
                            <KVKK/>
                        </Checkbox>
                    </Form.Item>
                </Col><Col className='footer-button' xs={24} sm={24} lg={24}>
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            {i18n.t("register")}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form >
    );
};

const mapDispatchToProps = { setLoading: baseActions.setLoading };

export default compose(withTranslation(), connect(null, mapDispatchToProps))(RegistrationForm);
export const authConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'USERS_LOGOUT',
    LOAD_MENU_ITEMS: 'LOAD_MENU_ITEMS',
    CLEAR_METADATA: 'CLEAR_METADATA',
    LOGIN_WITH_TOKEN: 'LOGIN_WITH_TOKEN',
    REFRESH: 'REFRESH',

};

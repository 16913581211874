import React from 'react';
import { withTranslation } from 'react-i18next';
import doc from '../../docs/KVKK.docx';
import i18n from '../../i18n';

const KVKK = () => {

    return (
        <a href={doc} download="KVKK.docx">{i18n.t("agreement")}</a>
    );
}

export default withTranslation()(KVKK);
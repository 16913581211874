export default {
    "faqh1": "Sık Sorulan Sorular",
    "faqh2": "Üyelik",
    "faqh3": "Hesabımı nasıl silebilirim?",
    "faqh3-1": "Aday yada firma üyeliğinizi  aşağıdaki iletişim bilgilerinden bizlere ulaşarak silinmesini talep edebilirsiniz? Üyeliğinizi sonlandırmanız durumunda sisteme giriş yapabilmek için yeniden üye olmanız gerektiğini ve veritabanımızdaki firmaların CV´nize ulaşamayacağını unutmayınız. Daha önce başvuru yaptığınız firmalar üyelik iptali yapmış olsanız bile CV nizi kaydetmiş olabilirler. E-Posta: destek@fethiyekariyer.com Cep: 0553 789 00 48 Tel: 0 252 614 0 365",
    "faqh4": "Kullanıcı adımı ve / veya şifremi hatırlayamıyorum, ne yapabilirim?",
    "faqh4-1": 'Fethiyekariyer.com´a üye girişi yaparken kullandığınız kullanıcı adı, şifre ya da e-posta bilginizi unuttuysanız Aday Giriş sayfasında "Şifremi Unuttum" linkine tıklayınız. Açılan sayfadaki yönergeleri uyguladığınızda; kullanıcı bilgileriniz kayıtlı e-posta adresinize gönderilecektir.',
    "faqh5": "Şifremi nasıl değiştirebilirim?",
    "faqh5-1": "Üye girişi yaptıktan sonra Hesabım sayfasında, soldaki menüden Şiferenizi Değiştirin linkini  tıklayarak eriştiğiniz sayfada şifrenizi değiştirebilirsiniz.    ",
    "faqh6": "Kayıtlı e posta adresimi nasıl değiştirebilirim?",
    "faqh6-1": 'Üye girişi yaptıktan sonra soldaki menüden "Profilim" seçeneğine tıklayarak açılan ekranda e-posta adresinizi değiştirebilirsiniz.',
    "faqh7": "Yayınlanan İş İlanlarına Nasıl Başvururum?",
    "faqh7-1": "Proin pretium eu purus id luctus. Aenean rhoncus felis neque, vitae ullamcorper magna pellentesque sed. Donec dapibus dapibus leo, vitae tristique sem. Nam leo magna, mollis rutrum vestibulum eget, posuere et lorem.",

    "faqh8": "Aday Üyeliği",
    "faqh9": "Bir firmayı nasıl takibe alabilirim?",
    "faqh9-1": 'Bir firmayı takip etmek için, ilan başlığı altında bulunan firma ismini tıklayıp, gelen firma sayfasında yer alan "takip et" butonu tıklayabilirsiniz.. Takip ettiğiniz ilanları yönetim panelinizde "Takip Ettiğin Firmalar" linkinden açılan sayfada görebilirsiniz.',
    "faqh10": "Bir ilanı nasıl takibe alabilirim?",
    "faqh10-1": 'Bir ilanı takip etmek için, ilanın sağında bulunan iconu tıklayabilirsiniz. Takip ettiğiniz ilanları yönetim panelinizde favori işlerim linkinden açılan sayfada görebilirsiniz.',
    "faqh11": "CV havuzunda yer almak istemiyorum, bunu nasıl yapabilirim?",
    "faqh11-1": 'Sadece sizin başvurduğunuz ilanın sahibi firma tarafından cv nizin görüntülenmesini istemeniz halinde, kayıt olurken yada kayıt olduktan sonra profilim bölümünde  CV Havuzunda Firmalar Sizi Görsün mü? seçeneğiini hayır olarak işaretleyebilirsiniz. Bu durumda CV havuzunda cv niz görünmeyecektir. Sadece başvurduğunuz firmalar tarafından görüntülenecektir.',
    "faqh12": "CV havuzunda yer almak için ne yapmam gerekiyor?",
    "faqh12-1": 'CV havuzunda yer alarak firmaların sizi bulmasını istiyorsanız, kayıt olurken yada kayıt olduktan sonra profilim bölümünde CV Havuzunda Firmalar Sizi Görsün mü? seçeneğini evet olarak işaretleyin. Bu durumda sadece ilanına başvurduğunuz firmalar değil, siteye üye diğer firmalarda CV nize ulaşabileceklerdir.',
    "faqh13": "Daha önce yaptığım bir başvuruyu nasıl silebilirim?",
    "faqh13-1": 'Üye girişi yaptıktan sonra soldaki menüden "Başvurduğum iilanlar" seçeneğine tıklayarak açılan ekranda mevcut başvurularınızı görebilirsiniz. İlgili başvurunun sağındaki "çöp kutusu "iconunu tıklayarak. Başvurunuzu geri alabilirsiniz? Not: Başvurunuzu geri alsanız bile, başvurunuz ile başvurunuzu geri aldığınız sürede firma yetkilisi cv nizi indirmiş olabilir.',
    "faqh14": "Başvuru yaptığım firma, diğer firmalara yaptığım başvuruyu görebilir mi?",
    "faqh14-1": 'Başvuru yaptığınız firmalar diğer firmalara yaptığınız başvuruları göremezler.',

    "faqh15": "CV Havuzu",
    "faqh16": "Adayların iletişim bilgilerini göremiyorum?",
    "faqh16-1": "Sadece size başvuruda bulunmuş olan adayların iletişim bilgileri sizinle paylaşılmaktadır. CV havuzundan ulaştığınız adayların KVK ( Kişisel Verilerin Korunması) Kanunu doğrultusunda sizlerle paylaşılamamaktadır. CV havuzundan iletişim kurmak istediğiniz bir aday olması durumunda"
        + "Adayın profil detay sayfasında Adayı Listeye Kaydet butonunu tıklıyorsunuz"
        + "Kaydettiğiniz adayları yönetim panelinizde solda kayıtlı adaylar bölümünde görebilirsiniz."
        + "Kaydettiğiniz adayın altında bulunan mesaj gönder butonundan, adaya mesaj gönderebilirsiniz.Gönderdiğiniz mesaj adayın sitede kayıtlı mail adresine gönderilmektedir.Mesaj içeriğinde firma bilginiz ile iletişim bilgilerinizi de paylaşmanızı tavsiye ederiz.",
    "faqh17": "FTSO üyesiyim ancak cv havuzuna neden erişemiyorum?",
    "faqh17-1": "CV havuzuna erişim tanımlarınız eksik olabilir. CV havuzuna erişim hakkı talebinde bulunmak için firma ünvanınız ile birlikte  fethiyekariyer.com sitesinde tanımladığınız mail adresinizi destek@fethiyekariyer.com mail adresine gönderebilirsiniz. Fethiye TSO üyeliğiniz kontrol edildikten sonra 24 saat içerisinde CV erişim hakkı tanımlanarak size bilgi verilecektir.",
    "faqh18": "CV havuzuna kimler erişebiliyor?",
    "faqh18-1": "CV havuzuna sadece FTSO üyeleri erişebilmekte",

    "faqh19": "Sorunuzu bulamadınız mı?",
    "faqh20": "Sorunuzun cevabını bulamıyor musunuz? Sorun değil!",
    "faqh21": "Sorunuzu Bize Gönderin",

}

import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import '../styles/layout.css';
import { Layout, BackTop, ConfigProvider, Spin } from 'antd';
import { CaretUpOutlined, } from '@ant-design/icons';
import AlertContainer from './AlertContainer';
//import FooterSection from './FooterSection';
import PrivacySection from './PrivacySection';
//import logoKariyer from '../images/layout/fethiye-kariyer-logo.png';
import { AntDesignLanguagePacks } from '../locales';
import 'moment/locale/tr';
import { drpDataActions } from '../store/actions/drp.data.actions';
import PageHeader from './PageHeader';
import { isMobile } from "react-device-detect";

const { Header, Content } = Layout;

const PageLayout = (props) => {
    const antDesignLang = AntDesignLanguagePacks[props.baseState.lang];


    useEffect(() => {
        if (!props.drpData || Object.keys(props.drpData).length === 0) {
            props.loadDrpData();
        }
    }, [])

    return (<ConfigProvider locale={antDesignLang}>
        <Layout className={`layout-${isMobile ? "mobile" : "desktop"}`}>
            <BackTop>
                <div className="back-top"><CaretUpOutlined /></div>
            </BackTop>
            <Header className={`layout-header-${isMobile ? "mobile" : "desktop"} sticky`}>
                <PageHeader {...props} />
            </Header>
            <Content className={`site-layout-background layout-content layout-main-${isMobile ? "mobile" : "desktop"}`}>
                <Spin spinning={props.baseState.loading} style={{ position: 'fixed' }}>
                    {props.children}
                </Spin>
                <AlertContainer />
            </Content>
            {/*<Footer>*/}
            {/*    <FooterSection />*/}
            {/*</Footer>*/}
            <PrivacySection />
        </Layout>
    </ConfigProvider>
    );
}

const mapStateToProps = state => {
    return { baseState: state.base, drpData: state.drpData };
}

const mapDispatchToProps = { loadDrpData: drpDataActions.load };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageLayout));
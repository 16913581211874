export default {
    "jobseekersheader1": "İŞ BULMANIN KOLAY YOLU",
    "jobseekersheader2": "Sizinle En Kısa Sürede Buluşmayı Bekleyen İlanlar",
    "jsh3": "Hemen Üye Olun",
    "jsh3-1": "Sistemin gelişmiş imkanlarından faydalanmak için ilk olarak üye olun.",
    "jsh4": "İlan Arayın",
    "jsh4-1": "Aradığınız iş için tüm ilanlarda gelişmiş arama özellikleri ile işinizi bulun.",
    "jsh5": "Başvuru ve İzleme",
    "jsh5-1": "İlana başvurunuzu yaptıktan sonra hesabınızda okunduğunu takip edin, firma ile bağlantıda kalın.",
    "jsh6": "İş Teklifi Alın",
    "jsh6-1": "Özgeçmişinizin görüntülenmesine izin verin, Firmalar sizi bulsun, iş teklifinde bulunsun.",
    "jsh7": "İŞ BULMAK ARTIK DAHA KOLAY",
    "jsh7-1": "Yukarıdaki avantajları ile ne kadar kısa sürede iş bulabileceğinizi öğrendiniz. Hemen şimdi Aday Üyeliğinizi başlatabilir veya İş İlanlarına göz atmakla işe başlayabilirsiniz",
    "jsh8": "İpucu: Eksiksiz Profil İle İşverenler Sizi Bulsun",
    "jsh9": "KARİYER HEDEFİNİZE ULAŞTIRACAK AYRICALIKLAR",
    "jsh9-1": "fethiyekariyer.com İle Kariyer Yolcuğunuz Daha Keyifli",
    "jsh10": "Favori İlanlarım",
    "jsh10-1": "İlginizi çeken, o an için başvurmak istemediğiniz ilanları favorilerinize ekleyip, takip edebilirsiniz, dilediğinizde başvurursunuz.",
    "jsh11": "Gelişmiş Gösterge Paneli",
    "jsh11-1": "Profil bilgileriniz ve özgeçmişiniz ne kadar eksiksiz olursa iş bulmanız da o kadar kolay olur. Gelişmiş gösterge paneli ile tüm bunları kolay ve hızlı yapabilirsiniz.",
    "jsh12": "Profil ve Özgeçmiş",
    "jsh12-1": "Gelişmiş profil ve özgeçmiş bölümleri eksiksiz doldurduğunuzda aday havuzunda yer alabilirsiniz. Siz ilanlara bakmadığınızda bile işverenler sizi bulabilir.",
    "jsh13": "Yorum Etkisi",
    "jsh13-1": "İlanlara yapacağınız yorumlar, vereceğiniz puanlarla işverene ve platforma destek olur, işverenlerin dikkatini çekebilirsiniz.",
    "jsh14": "Başvurduğum İlanlar",
    "jsh14-1": "Başvurduğunuz ilanları takibi sizin için önemlidir. Hangi başvurunuzun değerlendirmeye alındığını, beklemede olanları takip edebilirsiniz.",
    "jsh15": "İlan Uyarıları",
    "jsh15-1": "İlanları ararken kullandığınız filtre kriterlerini saklayabilir, buna uygun ilan geldiğinde otomatik haberdar edilirsiniz.",

}

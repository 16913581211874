import React from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import NavMenu from '../components/menu/NavMenu';
import HeaderAvatar from './HeaderAvatar';
import logoKariyer from '../images/layout/logo-eski.png';
import { isMobile } from "react-device-detect";

const PageHeader = (props) => {
    if (isMobile) {
        return (
            <Row gutter={[16, 16]} justify="center" align="top" wrap={false}>
                <Col flex="auto">
                    <NavMenu {...props} />
                </Col>
                <Col flex="none">
                    <HeaderAvatar />
                </Col>
            </Row>
        );
    } else {
        return (
            <Row gutter={[16, 16]} justify="center" align="top" wrap={false}>
                <Col flex="250px" style={{ paddingTop: "5px" }}>
                    <Link to="/">
                        <img className="logo" src={logoKariyer} alt="Logo" />
                    </Link>
                </Col>
                <Col flex="auto">
                    <NavMenu {...props} />
                </Col>
                <Col flex="none">
                    <HeaderAvatar />
                </Col>
            </Row>
        );
    }

}

export default PageHeader;

import { drpDataActionTypes as type } from "../actionTypes/drp.data.action.types";

const dataInStorage = JSON.parse(sessionStorage.getItem('fkDrpData') || null);

const initialData = dataInStorage || {};

const drpData = (state = initialData, action) => {
    switch (action.type) {
        case type.load:
            sessionStorage.setItem('fkDrpData', JSON.stringify(action.payload));
            return { ...state, ...action.payload };
        case type.clear:
            sessionStorage.removeItem("fkDrpData");
            return {};
        default:
            return state;
    }
};

export { drpData };
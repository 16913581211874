export default {
    "packageh1": "Informational Notes",
    "packageh2": "You can publish your ad by choosing one of the appropriate packages below. * You can find the element you are looking for more quickly with the packages that feature HIGH-UP ON THE FIRST PAGE.",
    "packageh3": "Use predefined/purchased package credit",
    "packageh4": "Successfully Purchased Ad Packages!",
    "packageh5": "Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait.",
    "publish": "Publish",
    "draft": "Draft",
    "awaiting-payment": "Awaiting Payment",
    "auto_draft": "Auto Draft",
    "inherit": "Inherit",
    "pending": "Pending",
    "wc_completed": "Wc Completed",
    "wc_on_hold": "Wc On Hold",
    "areyousuretodeletethistask": "Are you sure to delete this task?",
    "businesscredittobepublished": "Business credit to be published",
    "adboostcredit": "Ad boost credit",
    "viewcandidateCVs": "View candidate CVs",
    "ordernumber":"Order Number",
    "duration":"Duration",
    "status": "Status",
    "salartype_type_1": "Set salary",
    "salartype_type_2": "Monthly",
    "salartype_negotiable": "Negotiable",
    "appliedpostings": "Applied Postings",
    "favoritejobs": "Favorite Jobs",
    "followedcompanies": "Followed Companies",
    "cancompaniesseeyou": "Can Companies See You?",
    "generalinfo": "General Info",
    "jobcount": "Job Count",
    "jobtitle": "Job Title",   
    "viewscount": "ViewsCount",
    "companydescription": "Company Description",
    "resumecoverletter": "Application Cover Letter",
    "education": "Education",
    "academy": "Academy",
    "workexperience": "Work Experience",
    "expertise": "Expertise",
    "foreignlanguage": "Foreign Language",
    "iwanttofindthebesttalent": "I want to find the best talent",
    "iwanttofindajob": "i want to find a job",
    "candidate":"Candidate",
    "registertype":"Register Type",
    "showad":"Show Ad",
    "invoiceinformation":"Invoice Information",
    "country":"Country",
    "notdisplayed":"Not Displayed",
    "viewed": "Viewed",
    "decline": "Decline",
    "texadmin": "Tex Admin",
    "taxnumber": "Tax Number",
    "landphone": "Land Phone",
    "educationalstatus": "Educational Status",
    "placeofbirth": "Place of Birth",
    "nationality": "Nationality",
    "maritalstatus": "Marital Status",
    "militarystatus": "Military Status",
    "registrationnumber": "Registration Number",
    "fethiye-tso-uyesimisiniz": "Are you member of Fethiye TSO?",
 }
//antdesign dil paketleri
import ant_tr_TR from 'antd/lib/locale/tr_TR';
import ant_en_US from 'antd/lib/locale/en_US';

//i18n dil paketi
import enUS from "./langs/enUS";
import trTR from "./langs/trTR";


export const LanguagePacks = {
	"en-US": enUS,
	"tr-TR": trTR
};

export const AntDesignLanguagePacks = {
	"tr-TR": ant_tr_TR,
	"en-US": ant_en_US
};
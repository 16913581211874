import React from 'react';
import { Route } from 'react-router-dom';

const SamplePage = React.lazy(() => import('../pages/SamplePage'));
const AdminPage = React.lazy(() => import('../pages/AdminPage'));
const ProfilePage = React.lazy(() => import('../pages/ProfilePage'));
const Home = React.lazy(() => import('../pages/Home'));
const Login = React.lazy(() => import('../pages/Login'));
const JobPostingsPage = React.lazy(() => import('../pages/JobPostingsPage'));
const JobDetailPage = React.lazy(() => import('../pages/JobDetailPage'));
const JobEditPage = React.lazy(() => import('../pages/JobEditPage'));
const JobSeekersPage = React.lazy(() => import('../pages/JobSeekersPage'));
const EmployerPage = React.lazy(() => import('../pages/EmployerPage'));
const CompaniesPage = React.lazy(() => import('../pages/CompaniesPage'));
const AskedPage = React.lazy(() => import('../pages/AskedPage'));
const ContactForm = React.lazy(() => import('../components/contact/ContactForm'));
const TermsPage = React.lazy(() => import('../pages/TermsPage'));
const ResultPage = React.lazy(() => import('../pages/ResultPage'));
const ContactPage = React.lazy(() => import('../pages/ContactPage'));


export const MainRoutes = [
    <Route key="samplepageroute" exact path='/sample-page' component={SamplePage} />,
    <Route key="adminpageroute" exact path='/admin-page' component={AdminPage} />,
    <Route key="profilepageroute" exact path='/profile-page/:tabIndex?' component={ProfilePage} />,
    <Route key="homeroute" exact path='/' component={Home} />,
    <Route key="loginroute" exact path='/login/:referrer?' component={Login} />,
    <Route key="jobpostingsroute" exact path='/jobpostings-page/:searchedText?/:searchedSector?' component={JobPostingsPage} />,
    <Route key="jobdetailroute" exact path='/jobdetail-page/:guid?' component={JobDetailPage} />,
    <Route key="jobeditroute" exact path='/jobedit-page/:guid?' component={JobEditPage} />,
    <Route key="jobseekersroute" exact path='/jobseekers-page' component={JobSeekersPage} />,
    <Route key="employeroute" exact path='/employer-page' component={EmployerPage} />,
    <Route key="companiesroute" exact path='/companies-page' component={CompaniesPage} />,
    <Route key="askedroute" exact path='/frequentlyaskedquestions-page' component={AskedPage} />,
    <Route key="contactformroute" exact path='/contact-form' component={ContactForm} />,
    <Route key="termsroute" exact path='/terms-page' component={TermsPage} />,
    <Route key="resultroute" exact path='/result-page/:message?' component={ResultPage} />,
    <Route key="contactroute" exact path='/contact-page' component={ContactPage} />,
];